import React from 'react';
import { useSelector } from 'react-redux';

import SEO from '../../seo';
import Navigation from '../../Navigation/Profile';
import Users from './Dashboard/users';
import Ongs from './Dashboard/ong';
import Admin from './Dashboard/admin';

import { View } from './styles';

export default function Profile() {
  const profile = useSelector(state => state.user.profile);

  return (
    <>
      <SEO title="Perfil" profile={profile} />
      <View>
        <Navigation into="perfil" role={profile.role.title} />

        {profile.role.title === 'ADMINISTRADOR' && <Admin />}
        {profile.role.title === 'USER' && <Users />}
        {profile.role.title === 'MODERADOR' && <Ongs />}
      </View>
    </>
  );
}
