import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';
import api from '../../../../../services/api';

import Loading from '../../../../Loading/Backgrop';

import avatar from '../../../../../images/semavatar.jpg';
import SEO from '../../../../seo';
import Navigation from '../../../../Navigation/Profile';

import { View, Grid } from './styles';
import { BASE } from '../../../../../utils/environament';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Get({ token }) {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [users, setUsers] = useState([]);
  const [pet, setPet] = useState('');
  const [loading, setLoading] = useState(false);
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    if (profile.role.title !== 'MODERADOR') {
      navigate('/app/dashboard');
      return;
    }

    api.get(`getpets?user=true&slug=${token}`).then(response => {
      setUsers(response.data.data);
    });

    api.get(`pets/${token}?user=`).then(response => {
      setPet(response.data);
    });
  }, [profile.role.title]);

  const handleUpdate = useCallback(
    async event => {
      try {
        setLoading(true);
        await api.put(`getpets/${event}`);

        const percorre = users.map(user => ({
          ...user,
          read: user.id === event ? true : user.read,
        }));

        setUsers(percorre);
        setLoading(false);
      } catch (err) {
        toast.error('Algo deu errado.');
        setLoading(false);
      }
    },
    [users],
  );

  return (
    <>
      <SEO title="Perfil" />
      <View>
        <Navigation into="getpet" role={profile.role.title} />
        <Grid>
          <h4>Nome: {pet.name}</h4>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Foto</TableCell>
                  <TableCell align="left">Nome / Nasc.</TableCell>
                  <TableCell align="left">CPF</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Fones</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.file ? (
                        <img
                          src={`${url}${row.file.file}`}
                          alt={row.name}
                          style={{ width: 60 }}
                        />
                      ) : (
                        <img
                          src={avatar}
                          alt={row.name}
                          style={{ width: 60 }}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.user.name} <br />
                      {row.user.profile.nascimento}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.user.profile.cpf_cnpj}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {row.user.email}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {row.user.profile.celular} <br />
                      {row.user.profile.fone}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <div className="btn">
                        {row.read ? (
                          <button
                            type="button"
                            className="aceito"
                            title="Aceito"
                          >
                            Pedido Aceito
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="view"
                            onClick={() => handleUpdate(row.id)}
                            title="Aceitar"
                          >
                            Aceitar adoção
                          </button>
                        )}
                        <button
                          type="button"
                          className="redbtn"
                          onClick={() =>
                            navigate(
                              `/app/get/pdf/adocao/${token}?usuario=${row.user.id}`,
                            )
                          }
                        >
                          Visualizar Documento
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </View>

      <Loading loading={loading} />
    </>
  );
}
