import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaQuatro = ({ forms, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa4' });
    handleNext({ values, etapa: 'etapa5' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa4' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>
                4 - QUANTO AOS OUTROS ANIMAIS DA CASA/ANIMAIS QUE CRIOU NO
                PASSADO:
              </h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Assinale as opções abaixo:
                </FormLabel>
                <label>
                  <Field
                    type="checkbox"
                    name="jateve"
                    onChange={handleChange}
                    checked={values.jateve}
                  />
                  Já teve um animal?
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="outroanimal"
                    onChange={handleChange}
                    checked={values.outroanimal}
                  />
                  Tem outros animais?
                </label>
              </div>
            </BForm>
            {values.jateve && (
              <BForm>
                <div className="form-content">
                  <FormLabel component="legend">
                    O que houve com os seus antigos animais?
                  </FormLabel>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Faleceram de velhice"
                      checked={values.antigoanimal === 'Faleceram de velhice'}
                    />
                    Faleceram de velhice
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Faleceram por conta de uma doença"
                      checked={
                        values.antigoanimal ===
                        'Faleceram por conta de uma doença'
                      }
                    />
                    Faleceram por conta de uma doença
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Se perderam/fugiram"
                      checked={values.antigoanimal === 'Se perderam/fugiram'}
                    />
                    Se perderam/fugiram
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Morreu atropelado/envenenado"
                      checked={
                        values.antigoanimal === 'Morreu atropelado/envenenado'
                      }
                    />
                    Morreu atropelado/envenenado
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Doei para alguém de confiança"
                      checked={
                        values.antigoanimal === 'Doei para alguém de confiança'
                      }
                    />
                    Doei para alguém de confiança
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Doei para um estranho"
                      checked={values.antigoanimal === 'Doei para um estranho'}
                    />
                    Doei para um estranho
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Vendi"
                      checked={values.antigoanimal === 'Vendi'}
                    />
                    Vendi
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="antigoanimal"
                      value="Outros"
                      checked={values.antigoanimal === 'Outros'}
                    />
                    Outros
                  </label>
                </div>
              </BForm>
            )}
            {values.jateve || values.outroanimal ? (
              <>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Quantos animais, qual a espécie, e idade?"
                      variant="outlined"
                      name="quantosespecie"
                      value={values.quantosespecie}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="São/eram vacinados? Quais vacinas?"
                      variant="outlined"
                      name="quaisvacinas"
                      value={values.quaisvacinas}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Qual a marca da ração eles comem/comiam?"
                      variant="outlined"
                      name="racoescomem"
                      value={values.racoescomem}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Os seus animais são/eram castrados? Caso não, por quê?"
                      variant="outlined"
                      name="cadastrados"
                      value={values.cadastrados}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Seus animais já tiveram filhotes? Quantas vezes? O que você fez com os filhotes?"
                      variant="outlined"
                      name="tevefilhotes"
                      value={values.tevefilhotes}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
              </>
            ) : (
              ''
            )}
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
