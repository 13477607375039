import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaCinco = ({ forms, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa5' });
    handleNext({ values, etapa: 'etapa6' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa5' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>5 - CUIDADOS COM O FUTURO ANIMALZINHO</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Em relação aos passeios:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="passeios"
                    value="Ele terá acesso livre a rua quando quiser sair"
                    checked={
                      values.passeios ===
                      'Ele terá acesso livre a rua quando quiser sair'
                    }
                  />
                  Ele terá acesso livre a rua quando quiser sair
                </label>
                <label>
                  <Field
                    type="radio"
                    name="passeios"
                    value="Ensinarei ele a passear na rua sozinho e depois voltar para casa"
                    checked={
                      values.passeios ===
                      'Ensinarei ele a passear na rua sozinho e depois voltar para casa'
                    }
                  />
                  Ensinarei ele a passear na rua sozinho e depois voltar para
                  casa
                </label>
                <label>
                  <Field
                    type="radio"
                    name="passeios"
                    value="Só passeará na rua na minha presença, e somente com a guia"
                    checked={
                      values.passeios ===
                      'Só passeará na rua na minha presença, e somente com a guia'
                    }
                  />
                  Só passeará na rua na minha presença, e somente com a guia
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Qual a quantia mensal que poderá dispor aos cuidados dos
                  animais mensalmente?
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="cuidadomensal"
                    value="menos que 50 reais"
                    checked={values.cuidadomensal === 'menos que 50 reais'}
                  />
                  menos que 50 reais
                </label>
                <label>
                  <Field
                    type="radio"
                    name="cuidadomensal"
                    value="50 reais"
                    checked={values.cuidadomensal === '50 reais'}
                  />
                  50 reais
                </label>
                <label>
                  <Field
                    type="radio"
                    name="cuidadomensal"
                    value="50-100 reais"
                    checked={values.cuidadomensal === '50-100 reais'}
                  />
                  50-100 reais
                </label>
                <label>
                  <Field
                    type="radio"
                    name="cuidadomensal"
                    value="100-150 reais"
                    checked={values.cuidadomensal === '100-150 reais'}
                  />
                  100-150 reais
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Quanto tempo o animal ficará sozinho?"
                  variant="outlined"
                  name="sozinho"
                  value={values.sozinho}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Conseguem passear com animal quantas vezes ao dia/semana?"
                  variant="outlined"
                  name="passear"
                  value={values.passear}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Devolveria o animal caso precise mudar de casa ou de cidade? Como faria para se organizar nessa situação?"
                  variant="outlined"
                  name="devolveria"
                  value={values.devolveria}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Para qual destino seria o animal?
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="destinoanimal"
                    value="Guarda"
                    checked={values.destinoanimal === 'Guarda'}
                  />
                  Guarda
                </label>
                <label>
                  <Field
                    type="radio"
                    name="destinoanimal"
                    value="Companhia"
                    checked={values.destinoanimal === 'Companhia'}
                  />
                  Companhia
                </label>
                <label>
                  <Field
                    type="radio"
                    name="destinoanimal"
                    value="Presente"
                    checked={values.destinoanimal === 'Presente'}
                  />
                  Presente
                </label>
                <label>
                  <Field
                    type="radio"
                    name="destinoanimal"
                    value="Outros"
                    checked={values.destinoanimal === 'Outros'}
                  />
                  Outros:
                </label>
              </div>
            </BForm>
            {values.destinoanimal === 'Outros' && (
              <BForm>
                <div className="form-content">
                  <TextField
                    label="Fale aqui"
                    variant="outlined"
                    name="outros"
                    value={values.outros}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </BForm>
            )}
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
