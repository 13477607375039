import React, { useState, useCallback, useMemo, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdDelete } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { BASE } from '../../../utils/environament';

import { Grid, BForm } from './styles';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const url = `${BASE.API}/storage/`;

export default function UploadBanner() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*' });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const handleFile = useCallback(
    async e => {
      const fileTarget = e.target.files[0];

      const urlImagem = URL.createObjectURL(fileTarget);

      const filesPercorre = { files: fileTarget, baseURL: urlImagem };

      const dados = new FormData(); // eslint-disable-line

      dados.append('file', filesPercorre.files);
      try {
        const { data } = await api.post(
          `files?files=true&cropy=${0}&cropx=${0}&width=${1048}&height=${200}&upload=banners&subtype=true`,
          dados,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          },
        );

        const filesRes = [data, ...files];

        setFiles(filesRes);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    },
    [files],
  );

  const handleDelete = useCallback(
    async e => {
      setFiles(files.filter(item => item.id !== e));

      await api.delete(`files/${e}`);
    },
    [files],
  );

  function isValidUrl(str) {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i',
    );
    return pattern.test(str);
  }

  const handleUpdateLink = async (link, id) => {
    const fccUrl = isValidUrl(link);

    if (fccUrl) {
      try {
        await api.patch(`files/updatelink/${id}`, {
          link,
        });
      } catch (err) {
        toast.error('Algo deu errado.');
      }
    }
  };

  useEffect(() => {
    async function load() {
      const response = await api.get('files?subtype=banners');

      setFiles(response.data.data);
    }

    load();
  }, []);

  return (
    <>
      <Grid>
        <BForm>
          <h4>Adicionar Banners</h4>
        </BForm>
        <aside className="thumbsContainer">
          {loading && (
            <div className="thumb">
              <div className="thumbInner">
                <CircularProgress />
              </div>
            </div>
          )}
          {files.map(file => (
            <div className="thumb" key={file.id}>
              <button
                className="deletar"
                type="button"
                title="deletar"
                onClick={() => handleDelete(file.id)}
              >
                <MdDelete color="#fff" size={16} />
              </button>
              <div className="thumbInner">
                <img src={`${url}${file.file}`} alt="Teste" className="img" />
              </div>
              <input
                type="text"
                defaultValue={file.link}
                onChange={event =>
                  handleUpdateLink(event.target.value, file.id)
                }
                placeholder="Ao adicionar link é salvo automático"
              />
            </div>
          ))}
        </aside>
        <div className="container">
          <div {...getRootProps({ style })}>
            <input
              {...getInputProps()}
              multiple={false}
              onChange={handleFile}
            />
            <p>Arraste e solte alguma imagem aqui ou clique para selecionar</p>
          </div>
        </div>
      </Grid>
    </>
  );
}
