import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 40px;
  `;

export const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 28px;
  padding-bottom: 12px;

  h4 {
    font-size: 28px;
  }
`;

export const Banner = styled.div`
  margin-bottom: 20px;
  background: #fff;
  padding: 8px;
`;

export const Grid = styled.div`
  display: block;
  width: 100%;
`;

export const Description = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 12px;

  li {
    list-style: none;
  }

  .branco {
    background: #fff;
    padding: 8px 12px;
    border-radius: 4px;
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex-direction: column;

  .bubble {
    margin-top: 12px;
  }

  h4 {
    margin-bottom: 0px;
    font-size: 24px;
  }

  + div {
    margin-top: 8px;
    padding: 8px;
  }
`;

export const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #fff;
    padding: 4px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
`;

export const Shared = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  align-items: center;

  button + button {
    margin-left: 4px;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;

  .quero {
    display: none;
    border: none;
    background: #009595;
    padding: 10px 22px;
    font-size: 16px;
    color: #fff;

    &:hover {
      background: #F27900;
    }
  }

  .solicitou {
    display: none;
    border: none;
    background: #0080FF;
    padding: 10px 22px;
    font-size: 16px;
    color: #fff;

    &:hover {
      background: #0071E1;
    }
  }

  @media only screen and (min-width: 481px) {
    .quero {
      display: none;
    }

    .solicitou {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) {
    .quero {
      display: block;
    }

    .solicitou {
      display: block;
    }
  }

  @media only screen and (min-width: 1280px) {
    .quero {
      display: block;
    }

    .solicitou {
      display: block;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    background: #fff;

    flex: 1;

    li + li {
      border-left: 0;
    }

    button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 22px;
      border: none;
      background: none;
      font-size: 16px;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: #eee;
      }
    }
  }

  @media only screen and (min-width: 481px) {
    ul {
      flex-direction: row;

      li + li {
        border-left: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    ul {
      flex-direction:  row;

      li + li {
        border-left: 1px solid #ccc;
      }
    }
  }

  @media only screen and (min-width: 1280px) {
    ul {
      flex-direction:  row;

      li + li {
        border-left: 1px solid #ccc;
      }
    }
  }
`;

export const BtnFooter = styled.div`
  display: block;
  border: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;

  .btn {
    background: #0071E1;
  }

  button {
    display: block;
    width: 100%;
    background: #009595;
    padding: 20px 0px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border: none;
  }

  @media only screen and (min-width: 481px) {
    button {
      display: block;
    }
  }

  @media only screen and (min-width: 1024px) {
    button {
      display: none;
    }
  }

  @media only screen and (min-width: 1280px) {
    button {
      display: none;
    }
  }
`;

export const Icones = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
	grid-gap: 6px;

  @media only screen and (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const IconDiv = styled.div`
  background: ${Props => Props.active ? '#FF8040' : '#fff'};
  color: ${Props => Props.active ? '#fff' : '#000'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
  flex-direction: column;
  border-radius: 8px;

  h4 {
    font-size: 14px;
  }

  @media only screen and (min-width: 481px) {
    h4 {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1024px) {
    h4 {
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 1280px) {
    h4 {
      font-size: 24px;
    }
  }

  img {
      width: 40%;
      margin-bottom: 12px;
      filter: ${Props => Props.active ? "invert(100%)" : "invert(0%)"};
    }
`;

export const Comments = styled.div`
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 30px;

  .comment {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: #fff;
      padding: 4px;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 6px;

      p {
        background: #fff;
        padding: 8px 6px;
        border-radius: 6px;
        font-size: 11px;
      }

      small {
        font-size: 10px;
        padding-left: 2px;
        padding-top: 2px;
      }
    }
  }

  .comment + .comment {
    margin-top: 8px;
    border-top: 1px dotted #999;
    padding-top: 8px;
  }

  .btnComment {
    display: block;
    width: 100%;

    form {
      display: flex;
      margin-top: 40px;
      background: #fff;
      border-radius: 8px;
      justify-content: space-between;
      padding-left: 8px;

      input {
        width: 100%;
        background: none;
        border: none;
        height: 50px;
      }

      button {
        border: none;
        background: none;
        padding-right: 8px;
      }
    }
  }
`;
