import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaSeis = ({ forms, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa6' });
    handleNext({ values, etapa: 'etapa7' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa6' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>INFORMAÇÕES SOBRE CÔMODO DA CASA COM O ANIMAL</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Em qual cômodo da casa o animal ficará durante o dia:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="comodo"
                    value="Quintal/Área/Varanda"
                    checked={values.comodo === 'Quintal/Área/Varanda'}
                  />
                  Quintal/Área/Varanda
                </label>
                <label>
                  <Field
                    type="radio"
                    name="comodo"
                    value="Canil"
                    checked={values.comodo === 'Canil'}
                  />
                  Canil
                </label>
                <label>
                  <Field
                    type="radio"
                    name="comodo"
                    value="Amarrado"
                    checked={values.comodo === 'Amarrado'}
                  />
                  Amarrado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="comodo"
                    value="Dentro de casa"
                    checked={values.comodo === 'Dentro de casa'}
                  />
                  Dentro de casa
                </label>
                <label>
                  <Field
                    type="radio"
                    name="comodo"
                    value="Dentro de casa com acesso ao quintal"
                    checked={
                      values.comodo === 'Dentro de casa com acesso ao quintal'
                    }
                  />
                  Dentro de casa com acesso ao quintal
                </label>
              </div>
            </BForm>
            {values.comodo === 'Amarrado' && (
              <BForm>
                <div className="form-content">
                  <TextField
                    label="Por quais razões? Ele passaria quantas horas amarrado/preso?"
                    variant="outlined"
                    name="obsamarrado"
                    onChange={handleChange}
                    value={values.obsamarrado}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </BForm>
            )}
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Onde o animalzinho irá dormir:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="No chão do quintal/Área/Varanda"
                    checked={
                      values.ondedormir === 'No chão do quintal/Área/Varanda'
                    }
                  />
                  No chão do quintal/Área/Varanda
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="Na casinha/caminha na parte externa da casa"
                    checked={
                      values.ondedormir ===
                      'Na casinha/caminha na parte externa da casa'
                    }
                  />
                  Na casinha/caminha na parte externa da casa
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="Amarrado"
                    checked={values.ondedormir === 'Amarrado'}
                  />
                  Amarrado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="Na casinha/caminha na parte interna da casa"
                    checked={
                      values.ondedormir ===
                      'Na casinha/caminha na parte interna da casa'
                    }
                  />
                  Na casinha/caminha na parte interna da casa
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="No meu quarto/sala"
                    checked={values.ondedormir === 'No meu quarto/sala'}
                  />
                  No meu quarto/sala
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="No canil"
                    checked={values.ondedormir === 'No canil'}
                  />
                  No canil
                </label>
                <label>
                  <Field
                    type="radio"
                    name="ondedormir"
                    value="Amarrado"
                    checked={values.ondedormir === 'Amarrado'}
                  />
                  Amarrado
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Caso o animal cresça mais que o esperado, qual serão as
                  providências:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="animalcresca"
                    value="Irá doar para alguém com espaço maior a oferecer"
                    checked={
                      values.animalcresca ===
                      'Irá doar para alguém com espaço maior a oferecer'
                    }
                  />
                  Irá doar para alguém com espaço maior a oferecer
                </label>
                <label>
                  <Field
                    type="radio"
                    name="animalcresca"
                    value="Irá deixar ele somente no quintal"
                    checked={
                      values.animalcresca ===
                      'Irá deixar ele somente no quintal'
                    }
                  />
                  Irá deixar ele somente no quintal
                </label>
                <label>
                  <Field
                    type="radio"
                    name="animalcresca"
                    value="Levar para algum sítio"
                    checked={values.animalcresca === 'Levar para algum sítio'}
                  />
                  Levar para algum sítio
                </label>
                <label>
                  <Field
                    type="radio"
                    name="animalcresca"
                    value="Devolver para ong"
                    checked={values.animalcresca === 'Devolver para ong'}
                  />
                  Devolver para ong
                </label>
                <label>
                  <Field
                    type="radio"
                    name="animalcresca"
                    value="Continuar com ele"
                    checked={values.animalcresca === 'Continuar com ele'}
                  />
                  Continuar com ele
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Caso o animal faça bagunça ou destrua algo de valor, qual será sua atitude diante de tal ato?"
                  variant="outlined"
                  name="atitudeanimal"
                  onChange={handleChange}
                  value={values.atitudeanimal}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Em caso de viagem, quem ficará com o animal?"
                  variant="outlined"
                  name="casoviagem"
                  onChange={handleChange}
                  value={values.casoviagem}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Pretende se mudar em um espaço de 5 anos? Caso sim, como ficará o animal?"
                  variant="outlined"
                  name="pretendemudar"
                  onChange={handleChange}
                  value={values.pretendemudar}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="O que fará se o animalzinho chorar/latir/uivar durante o dia e noite?"
                  variant="outlined"
                  name="farialatir"
                  onChange={handleChange}
                  value={values.farialatir}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
