import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import cep from '../../../../../services/cep';
import CpfMaskCustom from '../../../../MaskInput/cpf';
import CelularMaskCustom from '../../../../MaskInput/celular';
import CepMaskCustom from '../../../../MaskInput/cep';

import { Grid, BForm } from './styles';

export const EtapaUm = ({ forms, handleNext }) => {
  const [data, setData] = useState({});

  const handleCep = async event => {
    const count = event.replace(/[^\d]+/g, '');
    if (count.length <= 7) {
      return;
    }

    const response = await cep.get(`${count}/json/`);

    const resCep = response.data;

    if (resCep.erro) {
      return;
    }

    const form = {
      ...data,
      cep: resCep.cep,
      end: resCep.logradouro,
      bairro: resCep.bairro,
      cidade: resCep.localidade,
      estado: resCep.uf,
    };

    setData(form);
  };

  const handleEtapaNext = values => {
    handleNext({ values, etapa: 'etapa2' });
  };

  useEffect(() => {
    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>1 - PREENCHA O FORMULÁRIO</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Nome Completo"
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="CPF"
                  name="cpf_cnpj"
                  variant="outlined"
                  value={values.cpf_cnpj}
                  InputProps={{
                    inputComponent: CpfMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  type="date"
                  label="Data de Nascimento"
                  variant="outlined"
                  name="nascimento"
                  value={values.nascimento}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </div>
              <div className="form-content">
                <TextField
                  label="Celular"
                  variant="outlined"
                  name="celular"
                  value={values.celular}
                  InputProps={{
                    inputComponent: CelularMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Cep"
                  name="cep"
                  variant="outlined"
                  value={values.cep}
                  handleCepEtapa
                  onChange={event => {
                    setFieldValue('cep', event.target.value);
                    handleCep(event.target.value);
                  }}
                  InputProps={{
                    inputComponent: CepMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="form-content">
                <TextField
                  label="Endereço"
                  variant="outlined"
                  name="end"
                  value={values.end}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Número"
                  variant="outlined"
                  name="numero"
                  value={values.numero}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="form-content">
                <TextField
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  value={values.complemento}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Bairro"
                  variant="outlined"
                  name="bairro"
                  value={values.bairro}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Cidade"
                  variant="outlined"
                  name="cidade"
                  value={values.cidade}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="form-content">
                <TextField
                  label="Estado"
                  variant="outlined"
                  name="estado"
                  value={values.estado}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
