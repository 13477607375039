import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  MdFavorite,
  MdStar,
  MdChatBubbleOutline,
  MdSend,
} from 'react-icons/md';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative, format } from 'date-fns';

import api from '../../../services/api';
import TableListVaccines from '../../TableListVaccines';

import SEO from '../../seo';

import avatar from '../../../images/semavatar.jpg';
import injecao from '../../../images/icones/injection-needle.svg';
import veterinario from '../../../images/icones/veterinary.svg';
import medicamento from '../../../images/icones/pill.svg';
import chipadoIcon from '../../../images/icones/memory.svg';

import { updateFavoriteRequest } from '../../../store/modules/user/actions';

import Carregando from '../../../images/carregando.jpg';

import {
  View,
  Banner,
  Grid,
  Submenu,
  Title,
  Description,
  Bloco,
  Author,
  Icones,
  IconDiv,
  Comments,
  BtnFooter,
  Shared,
} from './styles';
import { BASE } from '../../../utils/environament';

export default function PetShow({ token }) {
  const profile = useSelector(state => state.user.profile);
  const favourite = useSelector(state => state.user.favorite);
  const dispatch = useDispatch();
  const [pet, setPet] = useState('');
  const [tipo, setTipo] = useState('');
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState('');
  const [user, setUser] = useState('');
  const [heart, setHeart] = useState([]);
  const [form, setForm] = useState({
    comment: '',
  });
  const [comments, setComments] = useState([]);
  const [vaccines, setVaccines] = useState();

  const url = `${BASE.API}/storage/`;
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    async function loadPet() {
      const res = await api.get(
        `/pets/${token}?user=${profile ? profile.id : 0}`,
      );

      if (!res.data.active) {
        navigate('/');
        return;
      }

      const resComment = await api.get(`comments?id_pet=${res.data.id}`);

      const percorreComments = resComment.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
      }));

      const percorre = {
        ...res.data,
        dateFormatted: formatRelative(
          parseISO(res.data.created_at),
          new Date(),
          {
            locale: pt,
          },
        ),
        birthday: format(new Date(res.data.birthday), 'dd-MM-yyy'),
      };

      setVaccines(res.data.vaccines);
      setTipo(res.data.tipo.title);
      setFiles(res.data.files);
      setFile(res.data.files.length > 0 ? res.data.files[0].file : '');
      setPet(percorre);
      setUser(res.data.user);
      setHeart(res.data.heart);
      setComments(percorreComments);
    }

    loadPet();
  }, []);

  const comment = async e => {
    e.preventDefault();

    if (!profile) {
      navigate('/login');
      return;
    }

    try {
      const { data } = await api.post('comments', {
        text: form.comment,
        pet_id: pet.id,
      });

      if (data.error) {
        toast.warning(data.error);
        return;
      }

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(comments);

      setForm({ ...form, comment: '' });
      setComments(dadosArray);
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
    }
  };

  const handleLike = async e => {
    if (!profile) {
      navigate('/login');
      return;
    }

    if (!e) {
      try {
        const resLike = await api.post('likes', {
          pet_id: pet.id,
        });

        const percorre = {
          ...pet,
          like: true,
          like_id: resLike.data.id,
        };

        setPet(percorre);
        return;
      } catch (err) {
        toast.error('Algo deu errado.');
        return;
      }
    }

    await api.delete(`likes/${pet.like_id}`);

    const percorre = {
      ...pet,
      like: false,
      like_id: false,
    };

    setPet(percorre);
  };

  const handleFavorite = async e => {
    if (!profile) {
      navigate('/login');
      return;
    }

    if (!e) {
      const response = await api.post('favourite', {
        pet_id: pet.id,
      });

      const percorre = {
        ...pet,
        favourite: true,
        favourite_id: response.data.id,
      };

      setPet(percorre);
      dispatch(updateFavoriteRequest(favourite + 1));
      return;
    }

    await api.delete(`favourite/${pet.favourite_id}`);

    const percorre = {
      ...pet,
      favourite: false,
      favourite_id: false,
    };

    setPet(percorre);
    dispatch(updateFavoriteRequest(favourite - 1));
  };

  return (
    <>
      <SEO
        title={`Pet | ${pet.name}`}
        description={pet.description}
        url={`${BASE.API}/app/pet/${token}`}
        imagem={`${url}${file}`}
      />
      <View>
        <Title>
          Nome: <h4>{pet.name}</h4>
        </Title>

        <Banner>
          {files.length > 0 ? (
            <AutoplaySlider
              bullets={false}
              play
              cancelOnInteraction={false}
              interval={8000}
            >
              {files.map(item => (
                <div data-src={`${url}${item.file}`} />
              ))}
            </AutoplaySlider>
          ) : (
            <img src={Carregando} alt={pet.name} style={{ width: '100%' }} />
          )}
        </Banner>

        <Grid>
          <Description>
            <Bloco>
              <Shared>
                <p>Compartilhar pet:</p>
                <FacebookShareButton url={`${BASE.API}/app/pet/${token}`}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LinkedinShareButton url={`${BASE.API}/app/pet/${token}`}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <WhatsappShareButton url={`${BASE.API}/app/pet/${token}`}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Shared>
              <Author>
                {user.file_id ? (
                  <img src={`${url}${user.file}`} alt={user.name} />
                ) : (
                  <img src={avatar} alt={user.name} />
                )}

                <div>
                  <p>
                    Ong: <strong>{user.name}</strong>
                  </p>
                  <small>data: {pet.dateFormatted}</small>
                </div>
              </Author>
            </Bloco>
          </Description>

          <Submenu>
            <ul>
              <li>
                <button type="button" onClick={() => handleLike(pet.like)}>
                  <MdFavorite size={22} color={pet.like ? '#FF4A4A' : '#999'} />
                  Gostei
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => handleFavorite(pet.favourite)}
                >
                  <MdStar
                    size={22}
                    color={pet.favourite ? '#FF925E' : '#999'}
                  />
                  Favoritos
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => navigate(`/app/pet/${token}#comentario`)}
                >
                  <MdChatBubbleOutline size={22} color="#999" />
                  {comments.length > 1 ? (
                    <>Comentários ({comments.length})</>
                  ) : (
                    <>Comentário ({comments.length})</>
                  )}
                </button>
              </li>
            </ul>

            {pet.adquirido ? (
              <button type="button" className="solicitou">
                VOCÊ JÁ SOLICITOU ADOÇÃO
              </button>
            ) : (
              <>
                {profile && profile.id === pet.user_id ? (
                  <button type="button" className="quero">
                    VOCÊ É O ANUNCIANTE
                  </button>
                ) : (
                  <button
                    type="button"
                    className="quero"
                    onClick={() => {
                      if (!profile) {
                        navigate('/login');
                        return;
                      }
                      navigate(`/app/adotar/pet/${token}`);
                    }}
                  >
                    EU QUERO ADOTAR
                  </button>
                )}
              </>
            )}
          </Submenu>

          <Description>
            <Bloco className="branco">
              <h4>Descrição</h4>
              <li>
                <strong>Sexo:</strong>{' '}
                {pet.sexo === 'femea' ? 'FÊMEA' : 'MACHO'}
              </li>
              <li>
                <strong>Nascimento:</strong> {pet.birthday}
              </li>
              <li>
                <strong>Tipo:</strong> {tipo}
              </li>

              <div className="bubble">{pet.description}</div>
            </Bloco>
          </Description>

          <Description>
            <Bloco className="branco">
              <h4>Carteirinha de vacinação</h4>

              <TableListVaccines
                type="user"
                vaccines={vaccines}
                petSlug={pet?.slug}
              />
            </Bloco>
          </Description>

          <Icones>
            <IconDiv active={!!heart.find(item => item === 'vacinado')}>
              <img src={injecao} alt="vacinado" />
              <h4>Vacinado</h4>
            </IconDiv>

            <IconDiv active={!!heart.find(item => item === 'castrado')}>
              <img src={veterinario} alt="castrado" />
              <h4>Castrado</h4>
            </IconDiv>

            <IconDiv active={!!heart.find(item => item === 'vermifugado')}>
              <img src={medicamento} alt="vermifugado" />
              <h4>Vermifugado</h4>
            </IconDiv>

            <IconDiv active={!!heart.find(item => item === 'chipado')}>
              <img src={chipadoIcon} alt="microchipado" />
              <h4>Microchipado</h4>
            </IconDiv>
          </Icones>

          <Comments>
            {comments.map(item => (
              <div className="comment">
                {item.user.file ? (
                  <img src={`${url}${item.user.file}`} alt={item.user.name} />
                ) : (
                  <img src={avatar} alt={item.user.name} />
                )}

                <span>
                  <p>{item.text}</p>
                  <small>
                    por: {item.user.name} / postado: {item.dateFormatted}
                  </small>
                </span>
              </div>
            ))}

            <div className="btnComment">
              <form id="form">
                <input
                  type="text"
                  placeholder="Enviar Comentário"
                  onChange={e => setForm({ ...form, comment: e.target.value })}
                />
                <button type="submit" id="comentario" onClick={comment}>
                  <MdSend size={32} color="#999" />
                </button>
              </form>
            </div>
          </Comments>
        </Grid>
      </View>

      <BtnFooter>
        {pet.adquirido ? (
          <button type="button" className="btn">
            VOCÊ JÁ SOLICITOU ADOÇÃO
          </button>
        ) : (
          <button
            type="button"
            onClick={() => navigate(`/app/adotar/pet/${token}`)}
          >
            EU QUERO ADOTAR
          </button>
        )}
      </BtnFooter>
    </>
  );
}
