import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { navigate } from 'gatsby';

import api from '../../../../services/api';

import SEO from '../../../seo';
import Navigation from '../../../Navigation/Profile';

import UploadImage from './uploadImage';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '98%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CreateOrEdit() {
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    title: '',
    subtitle: '',
    body: '',
    type: 'post',
  });
  const [id, setId] = useState(null);

  const profile = useSelector(state => state.user.profile);
  const classes = useStyles();

  if (profile.role.title !== 'ADMINISTRADOR') {
    navigate('/app/dashboard');
  }

  useEffect(() => {
    const url_string = window.location.href; // eslint-disable-line
    const url = new URL(url_string);
    setId(url.searchParams.get('id'));
  }, []);

  const handleChange = event => {
    const text = event.target.value;
    const textbr = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

    setState({ ...state, body: textbr });
  };

  const handleSubmit = async () => {
    if (!state.title && !state.body) {
      toast.warning('Preencha os campos');
      return;
    }

    try {
      await api.post('posts', {
        ...state,
        image_id: file?.id ?? null,
      });

      toast.success('Adicionando com sucesso.');
      navigate('/app/eventos');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  };

  const handleUpdate = async () => {
    if (!state.title && !state.body) {
      toast.warning('Preencha os campos');
      return;
    }

    try {
      await api.patch(`posts/${id}`, {
        ...state,
        image_id: file?.id ?? null,
      });

      toast.success('Editado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  };

  useEffect(() => {
    async function loadGetPost() {
      const { data } = await api.get(`posts/${id}`);
      const getPost = data.data;

      if (getPost === null) {
        return;
      }

      if (getPost.image) {
        setFile(getPost.image);
      }

      setState({
        title: getPost.title,
        subtitle: getPost.subtitle,
        body: getPost.body,
        type: getPost.type,
      });
    }

    loadGetPost();
  }, []);

  return (
    <>
      <SEO title="Eventos" />
      <S.View>
        <Navigation into="events" role={profile.role.title} />

        <div id="container-grid">
          <div />
          <Button onClick={() => navigate('/app/eventos')}>Voltar</Button>
        </div>
      </S.View>

      <S.Grid>
        <div className="upload">
          <UploadImage
            file={file ? [file] : []}
            onChangedImage={file => setFile(file)}
          />
        </div>

        <div className="form-input">
          <TextField
            id="title"
            label="* Preencha o título"
            type="text"
            value={state.title}
            className={classes.textField}
            onChange={e => setState({ ...state, title: e.target.value })}
          />
        </div>

        <div className="form-input">
          <TextField
            id="subTitle"
            label="Preencha o sub título"
            type="text"
            value={state.subtitle}
            className={classes.textField}
            onChange={e => setState({ ...state, subtitle: e.target.value })}
          />
        </div>

        <div className="form-input">
          <textarea
            placeholder="* Escreva aqui..."
            defaultValue={state.body ?? ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-content">
          {id ? (
            <button type="button" className="btn-save" onClick={handleUpdate}>
              EDITAR EVENTO
            </button>
          ) : (
            <button type="button" className="btn-save" onClick={handleSubmit}>
              ADICIONAR EVENTO
            </button>
          )}
        </div>
      </S.Grid>
    </>
  );
}
