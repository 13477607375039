import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';

import { navigate, Link } from 'gatsby';

import { MdDelete, MdDone, MdModeEdit, MdClose } from 'react-icons/md';

import api from '../../../../services/api';

import { Grid } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Ongs() {
  const classes = useStyles();
  const [pets, setPets] = useState([]);
  const [, setLoading] = useState(true);
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    api.get(`pets`).then(response => {
      const percorre = response.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
      }));

      setLoading(false);
      setPets(percorre);
    });
  }, []);

  const handleUpdate = async event => {
    try {
      const { data } = await api.put(`pets/${event.id}?active=true`, {
        active: event.active,
      });

      const percorre = pets.map(item => ({
        ...item,
        active: item.id === event.id ? event.active : item.active,
      }));

      setPets(percorre);
      toast.success(data.success);
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
    }
  };

  const handleDelete = async event => {
    try {
      const { data } = await api.delete(`pets/${event}`);

      const percorre = pets.filter(item => item.id !== event);

      setPets(percorre);
      toast.success(data.success);
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
    }
  };

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell align="left">Nome do Pet</TableCell>
              <TableCell align="left">Curtidas</TableCell>
              <TableCell align="left">Favoritos</TableCell>
              <TableCell align="left">Interesse</TableCell>
              <TableCell align="left">Vacinas</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pets.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Link to={`/app/edit/adocao/${row.slug}`}>
                    {row.file && (
                      <img
                        src={`${url}${row.file.file}`}
                        alt={row.name}
                        style={{ width: 60 }}
                      />
                    )}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <button type="button" className="redbtn">
                    {row.likecount}
                  </button>
                </TableCell>
                <TableCell component="th" scope="row">
                  <button type="button" className="orangebtn">
                    {row.favouritecount}
                  </button>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <button
                    type="button"
                    className="greenbtn"
                    onClick={() => navigate(`/app/get/adocao/${row.slug}`)}
                  >
                    {row.getpet}
                  </button>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <button
                    type="button"
                    className="bluebtn"
                    onClick={() => navigate(`/app/vaccines/${row.slug}`)}
                  >
                    {row.vaccine_total}
                  </button>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <div className="btn">
                    {row.active ? (
                      <button
                        type="button"
                        className="done"
                        onClick={() =>
                          handleUpdate({ id: row.id, active: false })
                        }
                        title="Clique para desativar"
                      >
                        <MdDone size={18} color="#fff" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="delete"
                        onClick={() =>
                          handleUpdate({ id: row.id, active: true })
                        }
                        title="Clique para ativar"
                      >
                        <MdClose size={18} color="#fff" />
                      </button>
                    )}
                    <button
                      type="button"
                      className="view"
                      onClick={() => navigate(`/app/edit/adocao/${row.slug}`)}
                      title="Editar"
                    >
                      <MdModeEdit size={18} color="#fff" />
                    </button>
                    <button
                      type="button"
                      className="delete"
                      onClick={() => handleDelete(row.id)}
                      title="Excluir"
                    >
                      <MdDelete size={18} color="#fff" />
                    </button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
