import styled, { css } from '@react-pdf/styled-components';

export const Container = styled.View`
  display: flex;
  flex: 1;
`;

export const Header = styled.View`
  display: flex;
  width: 100%;
  height: 80px;
  background: #1497E4;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.Image`
  width: 50px;
  height: 50px;
`;

export const Termo = styled.View`
  padding-left: 20px;
  padding-right: 20px;
`;

export const Paragrafo = styled.Text`
  font-size: 8px;
  text-align: justify;
  margin-top: 10px;
  padding-left: 0;

  ${props => props.separa && css`
    padding-left: 12px;
  `}
`;

export const H4 = styled.Text`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 12px;
  padding-top: 12px;
  width: 100%;
`;

export const Perfil = styled.View`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
`;

export const Campo = styled.View`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const CampoTitle = styled.View`
  display: flex;
  flex: 1;
  margin-top: 5px;
`;

export const Title = styled.Text`
  font-size: 9px;
  font-weight: bold;
  margin-right: 6px;
  color: #000;
`;

export const CampoQuestion = styled.View`
  display: flex;
  flex-direction: column;
`;

export const BorderText = styled.View`
  display: flex;
  flex: 1;
  border-bottom-width: 0.5px;
  border-color: #000;
`;

export const Value = styled.Text`
  font-size: 9px;
`;

export const Question = styled.View``;

export const Perguntas = styled.View`
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
`;

export const H2 = styled.Text`
  font-size: 10px;
  font-weight: bold;
  margin-top: 8px;
`;

export const Strong = styled.Text`
  font-size: 9px;
  padding-bottom: 4px;
  padding-top: 6px;
`;
