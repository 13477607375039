import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-top: 40px;
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .form-input + .form-input {
    margin-top: 12px;
  }

  h4 {
    font-size: 24px;
    margin-bottom: 6px;
    color: #999;
  }

  input {
    width: 100%;
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 98%;
    padding-top: 6px;
    padding-bottom: 6px;

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #ff5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #ff4f4f;
      }
    }

    div {
      width: 100%;
    }
  }
`;
