import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';

import {
  Header,
  Logo,
  Termo,
  Paragrafo,
  H4,
  Perfil,
  Campo,
  Title,
  BorderText,
  Value,
  Perguntas,
  Strong,
  Question,
  CampoQuestion,
  CampoTitle,
  H2
} from './styles';

import LogoImg from '../../../../../../images/logo-pdf.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    flex: 1,
  },
  pageTwo: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    flex: 1,
    paddingTop: 12,
  }
});

// Create Document Component
const MyDocument = ({
  user,
  profile,
  etapadois,
  etapatres,
  etapaquarta,
  etapaquinta,
  etapasexta,
  etapasetima
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header>
        <Logo source={LogoImg} />
      </Header>
        <Termo>
            <H4>TERMO DE RESPONSABILIDADE DE ADOÇÃO </H4>
            <Perfil>
              <Campo>
                <Title>Nome:</Title>
                <BorderText>
                  <Value>{user.name}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Email:</Title>
                <BorderText>
                  <Value>{user.email}</Value>
                </BorderText>
              </Campo>
            </Perfil>
            <Perfil>
              <Campo>
                <Title>Data de Nascimento:</Title>
                <BorderText>
                  <Value>{profile.nascimento}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>CPF:</Title>
                <BorderText>
                  <Value>{profile.cpf_cnpj}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Celular:</Title>
                <BorderText>
                  <Value>{profile.celular}</Value>
                </BorderText>
              </Campo>
            </Perfil>
            <Perfil>
              <Campo>
                <Title>Endereço:</Title>
                <BorderText>
                  <Value>{profile.end}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Número / Complemento:</Title>
                <BorderText>
                  <Value>{profile.numero} / {profile.complemento}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Bairro:</Title>
                <BorderText>
                  <Value>{profile.bairro}</Value>
                </BorderText>
              </Campo>
            </Perfil>
            <Perfil>
              <Campo>
                <Title>Cidade:</Title>
                <BorderText>
                  <Value>{profile.cidade}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Estado:</Title>
                <BorderText>
                  <Value>{profile.estado}</Value>
                </BorderText>
              </Campo>
              <Campo>
                <Title>Cep:</Title>
                <BorderText>
                  <Value>{profile.cep}</Value>
                </BorderText>
              </Campo>
            </Perfil>

            <H2>INFORMAÇÕES SOBRE A RESIDÊNCIA</H2>
            <Question>
              <Strong>Mora em:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.moraem === 'Casa' && 'X'} ) Casa</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.moraem === 'Apartamento' && 'X'} ) Apartamento</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.moraem === 'Sítio' && 'X'} ) Sítio</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.moraem === 'Chácara' && 'X'} ) Chácara</Title>
                </Campo>
              </Perguntas>
              <Strong>Sua residencia é:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.suaresidencia === 'Alugada' && 'X'} ) Alugada</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.suaresidencia === 'Própria' && 'X'} ) Própria</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.suaresidencia === 'De um parente' && 'X'} ) De um parente</Title>
                </Campo>
              </Perguntas>
              <Strong>Sua redisência fica em condomínio?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.condominio === 'sim' && 'X'} ) Sim</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.condominio === 'nao' && 'X'} ) Não</Title>
                </Campo>
                <Campo>
                  <Title>Observação:</Title>
                  <BorderText>
                    <Value>{etapadois.obscondominio}</Value>
                  </BorderText>
                </Campo>
              </Perguntas>
              <Strong>O portão da sua residência que dá acesso a rua, é:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Gradeado' && 'X'} ) Gradeado</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Parte gradeado, parte fechado' && 'X'} ) Parte gradeado, parte fechado</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Totalmente fechado' && 'X'} ) Totalmente fechado</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Telado' && 'X'} ) Telado</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Não tem portão' && 'X'} ) Não tem portão</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.portaodeacesso === 'Outro' && 'X'} ) Outro:</Title>
                  <BorderText>
                    <Value>{etapadois.outroportao}</Value>
                  </BorderText>
                </Campo>
              </Perguntas>
              <Strong>Assinale as opções que sua residência possui:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.quinta && 'X' } ) Quintal</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.area && 'X' } ) Área</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.varanda && 'X' } ) Varanda</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.muroummetro && 'X' } ) Muro com mais de 2 metros</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.murodoismetro && 'X' } ) Muro com 1 metro</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.cercada && 'X' } ) Cercada</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.janelas && 'X' } ) Tem as janelas teladas</Title>
                </Campo>
              </Perguntas>
              <Strong>Em caso de haver quintal/área/varanda, elas são:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.gramado && 'X' } ) Gramado(a)</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.cimento && 'X' } ) No cimento</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.ceramica && 'X' } ) Cerâmica</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.coberto && 'X' } ) Coberto(a)</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.parcialcoberto && 'X' } ) Parcialmente coberto(a)</Title>
                </Campo>
                <Campo>
                  <Title>( {etapadois.opcoesresidencia.protecaocoberto && 'X' } ) Tem proteção contra a chuva</Title>
                </Campo>
              </Perguntas>
            </Question>

            <H2>INFORMAÇÕES SOBRE OS MORADORES DA CASA</H2>
            <Question>
              <CampoQuestion>
                <CampoTitle>
                  <Title>Reside com quantas pessoas? Quem são? Estão de acordo com a adoção?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.qtdpessoas ? etapatres.qtdpessoas : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Quem será o responsável financeiro pelo animal?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.responsavelfin ? etapatres.responsavelfin : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Se o animal mordesse/arranhasse você/seu filho/outro membro da família como seria a reação?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.reacao ? etapatres.reacao : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Possui veículo para o transporte do animal? Caso sim, qual? Caso não, como levará o animal para o veterinário?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.possuiveiculo ? etapatres.possuiveiculo : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Você trabalha no momento? Qual sua profissão/emprego.</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.trabalho ? etapatres.trabalho : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>As pessoas que moram com você trabalham? Qual a profissão delas?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.quemoratrabalha ? etapatres.quemoratrabalha : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Algum morador da residência é alérgico?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.moradoralergico ? etapatres.moradoralergico : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Tem crianças em casa? Caso tenha, qual a idade?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.temcrianca ? etapatres.temcrianca : '.'}</Value>
                </BorderText>
                <CampoTitle>
                  <Title>Alguém da casa dirige? Teria como levar o animalzinho imediatamente ao veterinário em caso de acidentes?</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapatres.casoacidente ? etapatres.casoacidente : '.'}</Value>
                </BorderText>
              </CampoQuestion>
            </Question>
            <H2>INFORMAÇÕES QUANTO AOS OUTROS ANIMAIS DA CASA/ANIMAIS QUE CRIOU NO PASSADO:</H2>
            <Question>
              <CampoQuestion>
                <CampoTitle>
                  <Title>Já teve/tem outros animais? Quantos, qual a espécie, e idade.</Title>
                </CampoTitle>
                <BorderText>
                  <Value>{etapaquarta.jateve ? 'SIM' : 'NAO'}</Value>
                </BorderText>
              </CampoQuestion>
              <Strong>O que houve com os seus antigos animais?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Faleceram de velhice' && 'X'} ) Faleceram de velhice</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Faleceram por conta de uma doença' && 'X'} ) Faleceram por conta de uma doença. Qual?:</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Se perderam/fugiram' && 'X'} ) Se perderam/fugiram</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Morreu atropelado/envenenado' && 'X'} ) Morreu atropelado/envenenado</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Doei para alguém de confiança' && 'X'} ) Doei para alguém de confiança</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Doei para um estranho' && 'X'} ) Doei para um estranho</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Vendi' && 'X'} ) Vendi</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquarta.antigoanimal === 'Outros' && 'X'} ) Outro:</Title>
                </Campo>
              </Perguntas>
            </Question>

        </Termo>
    </Page>
    <Page size="A4" style={styles.pageTwo}>
      <Termo>
          <Question>
            <CampoQuestion>
              <CampoTitle>
                <Title>São/eram vacinados? Quais vacinas?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquarta.quaisvacinas ? etapaquarta.quaisvacinas : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Qual a marca da ração eles comem/comiam?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquarta.racoescomem ? etapaquarta.racoescomem : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Os seus animais são/eram castrados? Caso não, por quê?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquarta.cadastrados ? etapaquarta.cadastrados : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Seus animais já tiveram filhotes? Quantas vezes? O que você fez com os filhotes?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquarta.tevefilhotes ? etapaquarta.tevefilhotes : '.'}</Value>
              </BorderText>
            </CampoQuestion>
          </Question>
          <H2>CUIDADOS COM O FUTURO ANIMALZINHO:</H2>
          <Question>
            <Strong>Em relação aos passeios:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquinta.etapaquinta === 'Ele terá acesso livre a rua quando quiser sair' && 'X'} ) Ele terá acesso livre a rua quando quiser sair</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.etapaquinta === 'Ensinarei ele a passear na rua sozinho e depois voltar para casa' && 'X'} ) Ensinarei ele a passear na rua sozinho e depois voltar para casa</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquinta.etapaquinta === 'Só passeará na rua na minha presença, porém sem guia' && 'X'} ) Só passeará na rua na minha presença, porém sem guia</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.etapaquinta === 'Só passeará na rua na minha presença, e somente com a guia' && 'X'} ) Só passeará na rua na minha presença, e somente com a guia</Title>
                </Campo>
              </Perguntas>
            <Strong>Qual a quantia mensal que poderá dispor aos cuidados dos animais mensalmente?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === 'menos que 50 reais' && 'X'} ) menos que 50 reais</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === '50 reais' && 'X'} ) 50 reais</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === '50-100 reais' && 'X'} ) 50-100 reais</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === '100-150 reais' && 'X'} ) 100-150 reais</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === '150-200 reais' && 'X'} ) 150-200 reais</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.cuidadomensal === 'mais que 200 reais' && 'X'} ) mais que 200 reais</Title>
                </Campo>
              </Perguntas>
            <CampoQuestion>
              <CampoTitle>
                <Title>Quanto tempo o animal ficará sozinho?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquinta.sozinho ? etapaquinta.sozinho : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Conseguem passear com animal quantas vezes ao dia/semana?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquinta.passear ? etapaquinta.passear : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Devolveria o animal caso precise mudar de casa ou de cidade? Como faria para se organizar nessa situação?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapaquinta.devolveria ? etapaquinta.devolveria : '.'}</Value>
              </BorderText>
            </CampoQuestion>
            <Strong>Para qual destino seria o animal?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapaquinta.destinoanimal === 'Guarda' && 'X'} ) Guarda </Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.destinoanimal === 'Companhia' && 'X'} ) Companhia</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.destinoanimal === 'Presente' && 'X'} ) Presente</Title>
                </Campo>
                <Campo>
                  <Title>( {etapaquinta.destinoanimal === 'Outros' && 'X'} ) Outros: {etapaquinta.outros}</Title>
                </Campo>
              </Perguntas>
          </Question>
          <H2>INFORMAÇÕES SOBRE CÔMODO DA CASA COM O ANIMAL</H2>
          <Question>
            <Strong>Em qual cômodo da casa o animal ficará durante o dia:</Strong>
            <Perguntas>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Quintal/Área/Varanda' && 'X'} ) Quintal/Área/Varanda</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Canil' && 'X'} ) Canil</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Amarrado' && 'X'} ) Amarrado</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Dentro de casa' && 'X'} ) Dentro de casa</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Dentro de casa com acesso ao quintal' && 'X'} ) Dentro de casa com acesso ao quintal</Title>
                </Campo>
              </Perguntas>
            <Strong>Onde o animalzinho irá dormir:</Strong>
            <Perguntas>
                <Campo>
                  <Title>( {etapasexta.comodo === 'No chão do quintal/Área/Varanda' && 'X'} ) No chão do quintal/Área/Varanda</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Na casinha/caminha na parte externa da casa' && 'X'} ) Na casinha/caminha na parte externa da casa</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Na casinha/caminha na parte interna da casa' && 'X'} ) Na casinha/caminha na parte interna da casa</Title>
                </Campo>
              </Perguntas>
            <Perguntas>
                <Campo>
                  <Title>( {etapasexta.comodo === 'No meu quarto/sala' && 'X'} ) No meu quarto/sala</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'No canil' && 'X'} ) No canil</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.comodo === 'Amarrado' && 'X'} ) Amarrado</Title>
                </Campo>
              </Perguntas>
            <CampoQuestion>
              <CampoTitle>
                <Title>O animal ficará amarrado/preso em algum lugar? Caso sim, por quais razões? Ele passaria quantas horas amarrado/preso?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasexta.obsamarrado}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Caso o animal faça bagunça ou destrua algo de valor, qual será sua atitude diante de tal ato?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasexta.atitudeanimal}</Value>
              </BorderText>
            </CampoQuestion>
            <Strong>Caso o animal cresça mais que o esperado, qual serão as providências:</Strong>
            <Perguntas>
                <Campo>
                  <Title>( {etapasexta.animalcresca === 'Irá doar para alguém com espaço maior a oferecer' && 'X'} ) Irá doar para alguém com espaço maior a oferecer</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.animalcresca === 'Irá deixar ele somente no quintal' && 'X'} ) Irá deixar ele somente no quintal</Title>
                </Campo>
            </Perguntas>
            <Perguntas>
                <Campo>
                  <Title>( {etapasexta.animalcresca === 'Levar para algum sítio' && 'X'} ) Levar para algum sítio</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.animalcresca === 'Devolver para ong' && 'X'} ) Devolver para ong</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasexta.animalcresca === 'Continuar com ele' && 'X'} ) Continuar com ele</Title>
                </Campo>
              </Perguntas>
            <CampoQuestion>
              <CampoTitle>
                <Title>Em caso de viagem, quem ficará com o animal?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasexta.casoviagem ? etapasexta.casoviagem : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Pretende se mudar em um espaço de 5 anos? Caso sim, como ficará o animal?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasexta.pretendemudar ? etapasexta.pretendemudar : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>O que fará se o animalzinho chorar/latir/uivar durante o dia e noite?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasexta.farialatir ? etapasexta.farialatir : '.'}</Value>
              </BorderText>
            </CampoQuestion>
          </Question>
          <H2>CUIDADOS VETERINÁRIOS QUE ANIMALZINHO RECEBERÁ</H2>
          <Question>
            <CampoQuestion>
              <CampoTitle>
                <Title>Quais vacinas irá dar ao animal adotado?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.quaisvacinas ? etapasetima.quaisvacinas : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Qual a marca de ração pretende dar ao adotado? Quais são os seus critérios ao escolher essa alimentação?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.marcaracao ? etapasetima.marcaracao : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Pretende por o animalzinho para ter filhotes ou irá castrar? O que pensa a respeito?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.castrarfilhote ? etapasetima.castrarfilhote : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>O seu adotado pode viver de 10 a 15 anos. Já pensou sobre essa responsabilidade e está preparado?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.viver ? etapasetima.viver : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>A adaptação do animal pode levar de 1 semana a 1 mês, tanto com o ambiente como com os outros moradores da casa (contando outros animais também). Está disposto a esperar esse tempo com paciência?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.terpaciencia ? etapasetima.terpaciencia : '.'}</Value>
              </BorderText>
              <CampoTitle>
                <Title>Qual clínica e veterinário levará ou leva os seus animais? Por favor coloque nome do estabelecimento, endereço e nome do veterinário responsável.</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.qualclinica ? etapasetima.qualclinica : '.'}</Value>
              </BorderText>
            </CampoQuestion>
          </Question>
      </Termo>
    </Page>
    <Page size="A4" style={styles.pageTwo}>
        <Termo>
          <Question>
            <Strong>O que faria se o animalzinho fosse diagnosticado com as devidas doenças: Calazar/Cinomose/Erliquiose:</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Sacrificaria, evitaria gastos com o tratamento e sofrimento do animal' && 'X'} ) Sacrificaria, evitaria gastos com o tratamento e sofrimento do animal</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Devolveria para a ong' && 'X'} ) Devolveria para a ong</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>Deixaria na Zoonoses</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Deixaria na rua' && 'X'} ) Deixaria na rua</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Doaria para alguém que poderia trata-lo' && 'X'} ) Doaria para alguém que poderia trata-lo</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Faria o tratamento até o fim, sem dúvidas' && 'X'} ) Faria o tratamento até o fim, sem dúvidas</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.diagnosticado === 'Não faria o tratamento, porém, cuidaria dele até o fim' && 'X'} ) Não faria o tratamento, porém, cuidaria dele até o fim</Title>
                </Campo>
              </Perguntas>
            <CampoQuestion>
              <CampoTitle>
                <Title>Conhece essas doenças citadas acima? Sabe as formas de precaução e tratamento?</Title>
              </CampoTitle>
              <BorderText>
                <Value>{etapasetima.conhecedoenca}</Value>
              </BorderText>
            </CampoQuestion>
            <Strong>Seus animais, tomam/tomavam/tomará o remédio de verme/carrapato/pulga com qual frequência?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.remedio === 'Nunca tomaram' && 'X'} ) Nunca tomaram</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.remedio === 'Uma vez por ano' && 'X'} ) Uma vez por ano</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.remedio === 'A cada 6 meses' && 'X'} )  A cada 6 meses</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.remedio === 'A cada 3 meses' && 'X'} ) A cada 3 meses</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.remedio === 'Raramente' && 'X'} ) Raramente</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.remedio === 'Só quando tem necessidade' && 'X'} ) Só quando tem necessidade.</Title>
                </Campo>
              </Perguntas>
            <Strong>Com qual frequência leva/levava/levará seus animais para o veterinário?</Strong>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'Nunca foram' && 'X'} ) Nunca foram</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'Uma vez por ano' && 'X'} ) Uma vez por ano</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'A cada 6 meses' && 'X'} ) A cada 6 meses</Title>
                </Campo>
              </Perguntas>
              <Perguntas>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'A cada 3 meses' && 'X'} ) A cada 3 meses</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'Raramente' && 'X'} ) Raramente</Title>
                </Campo>
                <Campo>
                  <Title>( {etapasetima.levarveterinario === 'Só quando tem necessidade' && 'X'} ) Só quando tem necessidade</Title>
                </Campo>
              </Perguntas>
          </Question>
          <Paragrafo>
            Ao adotar o animal acima descrito declaro-me apto para assumir a
            guarda e a responsabilidade sobre este animal, eximindo o doador de
            toda e qualquer responsabilidade por quaisquer atos praticados pelo
            animal a partir desta data, aceitando todas as condições descritas
            neste Termo, quais sejam:
            </Paragrafo>
            <Paragrafo separa>
            1) Declaro estar ciente de todos os cuidados que
            este animal exige, comprometo-me a não deixá-lo em corrente
            (ele terá que ter espaço para correr), em colocar uma coleira com placa de identificação, em
            providenciar-lhe um abrigo dentro de casa e se fora em local adequado
            (casinha de cachorro) para que não fique ao relento ou exposto ao sol,
            oferecendo-lhe ração de boa qualidade e água fresca diariamente;
            </Paragrafo>
            <Paragrafo separa>
            2) Estou ciente que deverá haver um período de adaptação
            de 15 dias e que tenho que ter atenção redobrada nesse período, evitando
            fugas e caso haja algum problema, avisarei imediatamente ao doador e
            deverei devolver o animal dentro desse período, em boas condições de
            saúde, tal qual estou recebendo neste ato – sem doença aparente, sem
            lesões na pele e no pelo, sem ectoparasitas.
            </Paragrafo>
            <Paragrafo separa>
            3) Responsabilizo-me por preservar a saúde e
            integridade do animal e a submetê-lo aos cuidados médico-veterinários
            sempre que necessário para este fim e a vaciná-lo anualmente com
            vacinas ÉTICAS; contra a raiva e viroses; e demais doenças que
            acometam cães e que para as quais haja vacina;
            </Paragrafo>
            <Paragrafo separa>
            4) Comprometo-me a não transmitir a posse deste
            animal a outrem, em nenhum momento, sem o conhecimento do doador;
            </Paragrafo>
            <Paragrafo separa>
            5) Comprometo-me a permitir o acesso do doador ao
            local onde se encontra o animal para averiguação de suas condições e
            permitir seu resgate em caso de não estar cumprindo com as obrigações e
            responsabilidades aqui assumidas, portanto, tenho conhecimento de que
            caso seja constatado por parte do doador situação inadequada para o
            bem-estar do animal, perderei a sua guarda, sem prejuízo das
            penalidades legais.
            </Paragrafo>
            <Paragrafo separa>
            6) Comprometo-me ainda em ESTERILIZAR o animal
            adotado se o doador já não o tiver feito, contribuindo assim para o
            controle da população de animais domésticos.
            </Paragrafo>
            <Paragrafo separa>
            7) Comprometo-me a cumprir toda a legislação
            vigente, municipal, estadual e federal, relativa à posse de animais.
            </Paragrafo>
            <Paragrafo>
            Declaro-me assim, ciente das normas acima, as quais aceito,
            assinando o presente Termo de Responsabilidade, assumindo
            plenamente os deveres que dele constam, bem como outros relacionados
            à posse responsável e que não estejam incluídos neste Termo.
            </Paragrafo>
        </Termo>
    </Page>
  </Document>
);

export default MyDocument;
