import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Grid = styled.div`
  display: block;
  width: 100%;
  margin-top: 40px;

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }

  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #004080;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;
