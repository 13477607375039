import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { Button } from '@material-ui/core';

import { updateProfilePageRequest } from '../../../../store/modules/user/actions';
import TableListVaccines from '../../../TableListVaccines';
import SEO from '../../../seo';
import Navigation from '../../../Navigation/Profile';
import api from '../../../../services/api';
import Alert from '../../../Alert';

import * as S from './styles';

export default function Vaccine({ petSlug }) {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const [active, setActive] = useState(!!profile.active);
  const [vaccines, setVaccines] = useState([]);
  const [pet, setPet] = useState(null);

  useEffect(() => {
    async function loadTabs() {
      const result = await api.get('app/users?verify=ong');

      if (result.data) {
        setActive(result.data);
        const users = {
          ...profile,
          active: result.data,
        };
        dispatch(updateProfilePageRequest(users));
      }
    }

    async function loadVaccines() {
      const result = await api.get(`vaccine-pets?pet_id=${petSlug}`);

      const { vaccines, ...rest } = result.data.data;

      setPet(rest);
      setVaccines(vaccines);
    }

    loadTabs();
    loadVaccines();
  }, []);

  return (
    <>
      <SEO title="Vacinação de pet" />

      <S.View>
        <Navigation into="perfil" role={profile.role.title} />

        {!active && (
          <Alert severity="warning">
            Ops! A sua conta ainda consta desativado. Aguarde o administrador
            confirmar.
          </Alert>
        )}

        <S.Grid>
          <S.Container>
            <div className="namePet">
              <strong>Nome pet:</strong> {pet?.name}
            </div>

            <div>
              {profile.role.title !== 'USER' && (
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(`/app/vaccine/create/${petSlug}`);
                  }}
                >
                  Adicionar
                </Button>
              )}
            </div>
          </S.Container>

          <TableListVaccines
            vaccines={vaccines}
            petSlug={petSlug}
            roleName={profile.role.title}
          />
        </S.Grid>
      </S.View>
    </>
  );
}
