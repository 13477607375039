import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 14px 6px;
  border-radius: 10px;

  .form-input + .form-input {
    margin-top: 12px;
  }

  .container {
    margin-bottom: 14px;
  }

  textarea {
    margin-left: 6px;
    margin-right: 6px;
    height: 80px;
    border: 1px solid #999;
    padding: 6px;
    border-radius: 6px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media only screen and (min-width: 481px) {
    .checkbox {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 1024px) {
    .checkbox {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 1280px) {
    .checkbox {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  button {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 12px;
  }

  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 140px;
    height: 120px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
  }

  .thumbInner {
    display: flex;
    align-items: center;
    min-width: 0px;
    overflow: hidden;
  }

  .img {
    display: block;
    width: auto;
    height: 100%;
  }

  .deletar {
    width: 28px;
    height: 28px;
    position: absolute;
    border: 0;
    background: #ff5353;
    border-radius: 14px;
    top: -15px;
    right: -15px;
  }
`;

export const Crop = styled.div`
  position: relative;
  display: block;
  width: 100%;

  .crop-container {
    display: block;
    height: 250px;
  }

  @media only screen and (min-width: 481px) {
    .crop-container {
      display: block;
      height: 250px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .crop-container {
      display: block;
      height: 400px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .crop-container {
      display: block;
      height: 500px;
    }
  }

  .controls {
    display: block;
    z-index: 99;
    width: 100%;
  }
`;
