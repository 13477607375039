import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import SEO from '../../seo';
import api from '../../../services/api';

import Loading from '../../Loading/Backgrop';

import { View, LoginView } from './styles';

export default function Login() {
  const profile = useSelector(state => state.user.profile);

  if (profile) {
    navigate('/app/editar');
  }

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error('Por favor! preencha o email.')
      return;
    }

    setLoading(true);

    try {
      const { data } = await api.post('password/create', {
        email,
      });

      toast.success(data.success);
      setLoading(false);
    } catch (err) {
      toast.warning(err.message);
      setLoading(false);
    }

  }, [email]);

  return (
    <>
      <SEO title="Password" />
      <View>
        <LoginView>
          <form
            method="post"
            onSubmit={e => handleSubmit(e)}
          >
            <h3>Esqueci minha senha</h3>

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              onChange={e => setEmail(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading ? (
                'Carregando...'
              ) : (
                'Resetar minha senha'
              )}
            </Button>

            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => navigate('/login')}
            >
              Voltar para login
            </Button>

          </form>
        </LoginView>
      </View>

      <Loading loading={loading} />
    </>
  );
}
