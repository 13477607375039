import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import SEO from '../../seo';
import api from '../../../services/api';

import Loading from '../../Loading/Backgrop';

import { View, LoginView } from './styles';

export default function Login({ token }) {
  const profile = useSelector(state => state.user.profile);

  if (profile) {
    navigate('/app/editar');
  }

  const [form, setForm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadToken() {
      const response = await api.get(`password/find/${token}`);

      const { error, email } = response.data;

      if (error) {
        toast.error(error);
        navigate('/login');
      }

      setForm({ email });
    }

    loadToken();
  }, [token]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password, confirmPassword } = form;

    if (!email) {
      toast.error('Por favor! preencha o email.');
      return;
    }

    if (!password) {
      toast.error('Preencha o campo senha.');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('A senha não bate.')
      return;
    }

    setLoading(true);

    try {
      await api.post('password/reset', {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
      });

      toast.success('Pronto! Atualizado com sucesso.');
      navigate('/login');
      setLoading(false);
    } catch (err) {
      toast.warning(err.message);
      setLoading(false);
    }

  }, [form, token]);

  return (
    <>
      <SEO title="Password" />
      <View>
        <LoginView>
          <form
            method="post"
            onSubmit={e => handleSubmit(e)}
          >
            <h3>Esqueci minha senha</h3>

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={form.email}
              name="email"
              onChange={e => setForm({ ...form, email: e.target.value })}
              InputLabelProps={{
                shrink: !!form.email,
              }}
            />

            <TextField
              id="outlined-basic"
              type="password"
              label="Nova Senha"
              variant="outlined"
              name="password"
              onChange={e => setForm({ ...form, password: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              type="password"
              label="Repete a Senha"
              variant="outlined"
              name="confirmPassword"
              onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading ? (
                'Carregando...'
              ) : (
                'Resetar minha senha'
              )}
            </Button>

            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => navigate('/login')}
            >
              Voltar para login
            </Button>

          </form>
        </LoginView>
      </View>

      <Loading loading={loading} />
    </>
  );
}
