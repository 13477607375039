import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';

import { Grid, BForm } from './styles';

export const EtapaFim = ({ forms, handleSubmit }) => {
  const [data, setData] = useState({});

  const handleEtapaNext = values => {
    handleSubmit({ values });
  };

  useEffect(() => {
    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleSubmit }) => (
        <Form>
          <Grid margin>
            <BForm>
              <h4>TERMO DE ADOTAR</h4>
            </BForm>
            <BForm>
              <div className="termo">
                <p>
                  Ao adotar o animal acima descrito declaro-me apto para assumir
                  a guarda e a responsabilidade sobre este animal, eximindo o
                  doador de toda e qualquer responsabilidade por quaisquer atos
                  praticados pelo animal a partir desta data, aceitando todas as
                  condições descritas neste Termo, quais sejam:
                </p>
                <p className="paragrafo">
                  <strong>1)</strong> Declaro estar ciente de todos os cuidados
                  que este animal exige, comprometo-me a não deixá-lo em
                  corrente (ele terá que ter espaço para correr), em colocar uma
                  coleira com placa de identificação, em providenciar-lhe um
                  abrigo dentro de casa e se fora em local adequado (casinha de
                  cachorro) para que não fique ao relento ou exposto ao sol,
                  oferecendo-lhe ração de boa qualidade e água fresca
                  diariamente;
                </p>
                <p className="paragrafo">
                  <strong>2)</strong> Estou ciente que deverá haver um período
                  de adaptação de 15 dias e que tenho que ter atenção redobrada
                  nesse período, evitando fugas e caso haja algum problema,
                  avisarei imediatamente ao doador e deverei devolver o animal
                  dentro desse período, em boas condições de saúde, tal qual
                  estou recebendo neste ato – sem doença aparente, sem lesões na
                  pele e no pelo, sem ectoparasitas.
                </p>
                <p className="paragrafo">
                  <strong>3)</strong> Responsabilizo-me por preservar a saúde e
                  integridade do animal e a submetê-lo aos cuidados
                  médico-veterinários sempre que necessário para este fim e a
                  vaciná-lo anualmente com vacinas ÉTICAS; contra a raiva e
                  viroses; e demais doenças que acometam cães e que para as
                  quais haja vacina;
                </p>
                <p className="paragrafo">
                  <strong>4)</strong> Comprometo-me a não transmitir a posse
                  deste animal a outrem, em nenhum momento, sem o conhecimento
                  do doador;
                </p>
                <p className="paragrafo">
                  <strong>5)</strong> Comprometo-me a permitir o acesso do
                  doador ao local onde se encontra o animal para averiguação de
                  suas condições e permitir seu resgate em caso de não estar
                  cumprindo com as obrigações e responsabilidades aqui
                  assumidas, portanto, tenho conhecimento de que caso seja
                  constatado por parte do doador situação inadequada para o
                  bem-estar do animal, perderei a sua guarda, sem prejuízo das
                  penalidades legais.
                </p>
                <p className="paragrafo">
                  <strong>6)</strong> Comprometo-me ainda em ESTERILIZAR o
                  animal adotado se o doador já não o tiver feito, contribuindo
                  assim para o controle da população de animais domésticos.
                </p>
                <p className="paragrafo">
                  <strong>7)</strong> Comprometo-me a cumprir toda a legislação
                  vigente, municipal, estadual e federal, relativa à posse de
                  animais.
                </p>
                <p>
                  Declaro-me assim, ciente das normas acima, as quais aceito,
                  assinando o presente Termo de Responsabilidade, assumindo
                  plenamente os deveres que dele constam, bem como outros
                  relacionados à posse responsável e que não estejam incluídos
                  neste Termo.
                </p>
              </div>
            </BForm>
            <BForm>
              <label>
                <Field
                  type="radio"
                  name="confirm"
                  value="lido"
                  checked={values.confirm === 'lido'}
                />
                Li e concordo
              </label>
            </BForm>
          </Grid>

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SOLICITAR ADOÇÃO
          </Button>
        </Form>
      )}
    </Formik>
  );
};
