import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  .img-avatar {
    width: 100%;
  }
`;
