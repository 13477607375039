import React from 'react';

import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { MdModeEdit } from 'react-icons/md';
import { format, parseISO, isAfter, addDays, isPast } from 'date-fns';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function getStatusVaccination(prevision, vaccination) {
  if (!vaccination) {
    if (prevision) {
      const validationDateAfter = isAfter(
        new Date(parseISO(prevision)),
        addDays(new Date(), 3),
      );
      const validationPastDate = isPast(new Date(parseISO(prevision)));

      if (validationDateAfter) {
        return (
          <Chip
            size="small"
            label="No Prazo"
            style={{
              marginRight: 6,
              backgroundColor: '#0271d1',
              color: 'white',
            }}
          />
        );
      }

      if (validationPastDate) {
        return (
          <Chip
            size="small"
            label="Atrasado"
            style={{
              marginRight: 6,
              backgroundColor: '#f33e16',
              color: 'white',
            }}
          />
        );
      }

      return (
        <Chip
          size="small"
          label="À vencer"
          style={{
            marginRight: 6,
            backgroundColor: '#f28d09',
            color: 'white',
          }}
        />
      );
    }
  }

  return (
    <Chip
      size="small"
      label="Aplicada"
      style={{
        marginRight: 6,
        backgroundColor: '#21bd0c',
        color: 'white',
      }}
    />
  );
}

export default function TableListVaccines({
  vaccines = [],
  type = 'ong',
  RoleName = '',
  petSlug,
}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Vacina</TableCell>
            <TableCell align="left">Dose</TableCell>
            <TableCell align="left">Previsão</TableCell>
            <TableCell align="left">Vacinação</TableCell>
            <TableCell align="left">Obs</TableCell>
            {type === 'ong' && <TableCell align="right">Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {vaccines.map(item => (
            <TableRow key={`${item.id}-vaccine`}>
              <TableCell component="th" scope="row">
                {item.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {getStatusVaccination(item.prevision, item.vaccination)}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.vaccine?.text ?? ''}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.dose}
              </TableCell>
              <TableCell component="th" scope="row">
                {format(parseISO(item.prevision), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.vaccination
                  ? format(parseISO(item.vaccination), 'dd/MM/yyyy')
                  : '...'}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.obs ?? '...'}
              </TableCell>
              {type === 'ong' && (
                <TableCell component="th" scope="row" align="right">
                  <div className="btn">
                    {RoleName !== 'USER' &&
                      item.prevision &&
                      !item.vaccination && (
                        <button
                          type="button"
                          className="view"
                          onClick={() => {
                            navigate(
                              `/app/vaccine/edit/${petSlug}/${item.vaccine_id}`,
                            );
                          }}
                          title="Editar"
                        >
                          <MdModeEdit size={18} color="#fff" />
                        </button>
                      )}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
