import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  button {
    width: 100%;
    margin-top: 12px;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 28px;
  padding-bottom: 12px;

  h4 {
    font-size: 28px;
    margin-left: 6px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin: 0px auto;
  border: 6px solid #fff;
  border-radius: 80px;
  background: #fff;
  overflow: hidden;

  img {
    height: 100%;
  }
`;

export const Description = styled.div`
  display: block;
  width: 100%;
  max-width: 420px;
  text-align: center;
  padding: 6px;
  background: #fff;
  margin: 8px auto;
  border-radius: 4px;
`;

export const Etapas = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  button + button {
    margin-top: 4px;
  }
`;
