import React from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router'; //eslint-disable-line
import PrivateRoute from '../components/PrivateRoute';

import Profile from '../components/pages/Profile';
import Tools from '../components/pages/Tools';
import Adocao from '../components/pages/Profile/Adocao';
import AdocaoGet from '../components/pages/Profile/Adocao/Get';
import AdocaoEdit from '../components/pages/Profile/Adocao/Edit';
import Eventos from '../components/pages/Profile/Eventos';
import Create from '../components/pages/Profile/Eventos/createOrEdit';
import EditEvent from '../components/pages/Profile/Eventos/createOrEdit';
import Favorito from '../components/pages/Profile/Favorite';
import Edit from '../components/pages/Profile/Edit';
import PetShow from '../components/pages/Pet';
import QueroPet from '../components/pages/Pet/Form';
import SuccessPet from '../components/pages/Pet/Form/Success';
import viewPdf from '../components/pages/Profile/Adocao/Get/Pdf/view';
import UserPDF from '../components/pages/Profile/Adocao/Get/Pdf/userview';
import Password from '../components/pages/Login/password';
import PasswordReset from '../components/pages/Login/newPassword';
import VaccinePage from '../components/pages/Profile/Vaccine';
import CreateVaccine from '../components/pages/Profile/Vaccine/editOrCreate';

export default function Route() {
  const profile = useSelector(state => state.user.profile);

  return (
    <Router>
      <PrivateRoute
        path="/app/dashboard"
        component={Profile}
        signed={!!profile}
      />
      <PrivateRoute path="/app/adocao" component={Adocao} signed={!!profile} />
      <PrivateRoute
        path="/app/get/adocao/:token"
        component={AdocaoGet}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/get/pdf/adocao/:token"
        component={viewPdf}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/vaccines/:petSlug"
        component={VaccinePage}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/vaccine/create/:petSlug"
        component={CreateVaccine}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/vaccine/edit/:petSlug/:vaccineId"
        component={CreateVaccine}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/edit/adocao/:token"
        component={AdocaoEdit}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/favorito"
        component={Favorito}
        signed={!!profile}
      />
      <PrivateRoute path="/app/editar" component={Edit} signed={!!profile} />
      <PrivateRoute
        path="/app/eventos"
        component={Eventos}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/eventos/create"
        component={Create}
        signed={!!profile}
      />
      <PrivateRoute
        path="/app/eventos/edit"
        component={EditEvent}
        signed={!!profile}
      />
      <PrivateRoute path="/app/tools" component={Tools} signed={!!profile} />
      <PetShow path="/app/pet/:token" />
      <QueroPet path="/app/adotar/pet/:token" />
      <SuccessPet path="/app/adotar/success/:token" />

      <UserPDF path="/pdf/:token" />
      <Password path="/password/create" />
      <PasswordReset path="/password/reset/:token" />
    </Router>
  );
}
