import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';

import { MdClose, MdEdit } from 'react-icons/md';

import SEO from '../../../seo';
import Navigation from '../../../Navigation/Profile';

import api from '../../../../services/api';

import * as S from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const url = `${BASE.API}/storage/`;

export default function Eventos() {
  const [, setLoading] = useState([]);
  const [posts, setPosts] = useState([]);
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);

  if (profile.role.title !== 'ADMINISTRADOR') {
    navigate('/app/dashboard');
  }

  const getPosts = async () => {
    const response = await api.get(`posts?type=post`);

    const percorre = response.data.data.map(item => ({
      ...item,
      dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
        locale: pt,
      }),
    }));

    setLoading(false);
    setPosts(percorre);
  };

  const handleDelete = async id => {
    try {
      await api.delete(`posts/${id}`);

      getPosts();

      toast.success('Deletado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  };

  useEffect(() => {
    async function loadPets() {
      getPosts();
    }

    loadPets();
  }, []);

  return (
    <>
      <SEO title="Eventos" />
      <S.View>
        <Navigation into="events" role={profile.role.title} />

        <div id="container-grid">
          <div />
          <S.Button onClick={() => navigate('/app/eventos/create')}>
            Criar Evento
          </S.Button>
        </div>
      </S.View>

      <S.TableView>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Imagem</TableCell>
                <TableCell align="left">Título</TableCell>
                <TableCell align="left">Sub título</TableCell>
                <TableCell align="left">Data de criação</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.image && (
                      <img
                        src={`${url}${row.image.file}`}
                        alt={row.name}
                        style={{ width: 60 }}
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.subtitle}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.dateFormatted}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <div className="btn">
                      <button
                        type="button"
                        className="done"
                        onClick={() =>
                          navigate(`/app/eventos/edit?id=${row.id}`)
                        }
                        title="Clique para editar"
                      >
                        <MdEdit size={18} color="#fff" />
                      </button>

                      <button
                        type="button"
                        className="delete"
                        onClick={() => handleDelete(row.id)}
                        title="Clique para ativar"
                      >
                        <MdClose size={18} color="#fff" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </S.TableView>
    </>
  );
}
