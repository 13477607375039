import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';

import {
  MdDone,
  MdPerson,
  MdBusiness,
  MdPets,
  MdGavel,
  MdClose,
} from 'react-icons/md';

import api from '../../../../services/api';

import { Grid } from './styles';

import avatar from '../../../../images/semavatar.jpg';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Ongs() {
  const classes = useStyles();
  const [profiles, setProfiles] = useState([]);
  const [graficos, setGraficos] = useState({
    users: 0,
    ongs: 0,
    pets: 0,
    getpets: 0,
  });
  const [, setLoading] = useState(true);
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    async function loadPets() {
      const response = await api.get(`dashboard/users`);
      const resGraficos = await api.get('graficos');

      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
      }));

      setLoading(false);
      setProfiles(percorre);
      setGraficos(resGraficos.data);
    }

    loadPets();
  }, []);

  const handleUpdate = async ({ id, active }) => {
    await api.put(`users/${id}`, {
      active,
    });

    const percorre = profiles.map(item => ({
      ...item,
      active: item.id === id ? active : item.active,
    }));

    setProfiles(percorre);
  };

  return (
    <Grid>
      <div className="bloco">
        <div className="table">
          <div>
            <h4 className="green">{graficos.users}</h4>
            <p>Usuários</p>
          </div>
          <span>
            <MdPerson size={48} color="#999" />
          </span>
        </div>

        <div className="table">
          <div>
            <h4 className="green">{graficos.ongs}</h4>
            <p>Ongs</p>
          </div>
          <span>
            <MdBusiness size={48} color="#999" />
          </span>
        </div>

        <div className="table">
          <div>
            <h4 className="green">{graficos.pets}</h4>
            <p>Pets para adoção</p>
          </div>
          <span>
            <MdPets size={48} color="#999" />
          </span>
        </div>

        <div className="table">
          <div>
            <h4 className="green">{graficos.getpets}</h4>
            <p>Pets doados</p>
          </div>
          <span>
            <MdGavel size={48} color="#999" />
          </span>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Cpf / Cnpj</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.file ? (
                    <img
                      src={`${url}${row.file.file}`}
                      alt={row.name}
                      style={{ width: 60 }}
                    />
                  ) : (
                    <img src={avatar} alt={row.name} style={{ width: 60 }} />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title === 'ADMINISTRADOR' && 'Admin'}
                  {row.title === 'USER' && 'User'}
                  {row.title === 'MODERADOR' && 'Ong'}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.profile ? row.profile.cpf_cnpj : ''}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <div className="btn">
                    {row.active ? (
                      <button
                        type="button"
                        className="done"
                        onClick={() =>
                          handleUpdate({ id: row.id, active: false })
                        }
                        title="Clique para Desativar Usuário"
                      >
                        <MdDone size={18} color="#fff" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="delete"
                        onClick={() =>
                          handleUpdate({ id: row.id, active: true })
                        }
                        title="Clique para ativar"
                      >
                        <MdClose size={18} color="#fff" />
                      </button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
