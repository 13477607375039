import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';

import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdDelete } from 'react-icons/md';

import { navigate } from 'gatsby';
import ModalSlide from '../../../Modal';

import api from '../../../../services/api';

import SEO from '../../../seo';
import Navigation from '../../../Navigation/Profile';
import Alert from '../../../Alert';

import { updateProfilePageRequest } from '../../../../store/modules/user/actions';

import { View, Grid, Crop } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '98%',
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function Adocao() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const url = `${BASE.API}/storage/`;
  const [state, setState] = useState({
    name: '',
    tipo: 0,
    idade: 0,
    porte: 0,
    sexo: '',
    date: '',
    active: 'ativo',
    description: '',
  });
  const [tabelas, setTabelas] = useState({
    tipo: [],
    idade: [],
    porte: [],
  });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState('');
  const [zoom, setZoom] = useState(1);
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(!!profile.active);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*' });

  useEffect(() => {
    if (profile.role.title !== 'MODERADOR') {
      navigate('/app/dashboard');
      return;
    }

    async function loadTabs() {
      const result = await Promise.all([
        api.get('tabelas?except=tab_raca'),
        api.get('app/users?verify=ong'),
      ]);

      if (result[1].data) {
        setActive(result[1].data);
        const users = {
          ...profile,
          active: result[1].data,
        };
        dispatch(updateProfilePageRequest(users));
      }

      const percorretipo = result[0].data.filter(
        item => item.tipo === 'tab_tipo',
      );
      const percorreidade = result[0].data.filter(
        item => item.tipo === 'tab_idade',
      );
      const percorreporte = result[0].data.filter(
        item => item.tipo === 'tab_porte',
      );

      setTabelas({
        tipo: percorretipo,
        idade: percorreidade,
        porte: percorreporte,
      });
    }

    loadTabs();
  }, [profile.role.title]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'blob',
      );
    });

  const handleFile = useCallback(
    async e => {
      const fileTarget = e.target.files[0];
      const image = await resizeFile(fileTarget);

      const urlImagem = URL.createObjectURL(image);

      const filesPercorre = { files: image, baseURL: urlImagem };

      setOpen(true);
      setFile(filesPercorre);
    },
    [files],
  );

  const handleSaveFile = useCallback(async () => {
    setLoading(true);
    setOpen(false);

    const dados = new FormData(); // eslint-disable-line

    dados.append('file', file.files);
    try {
      const { data } = await api.post(
        `files?files=true&cropy=${cropArea.y}&cropx=${cropArea.x}&width=${cropArea.width}&height=${cropArea.height}&upload=pets`,
        dados,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        },
      );

      const filesRes = [data, ...files];

      setFiles(filesRes);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }, [file, cropArea]);

  const handleDelete = useCallback(
    async e => {
      setFiles(files.filter(item => item.id !== e));

      await api.delete(`files/${e}`);
    },
    [files],
  );

  const handleChecked = useCallback(
    ({ tipo, check }) => {
      if (check) {
        const tipoChecked = [...checked, tipo];
        setChecked(tipoChecked);

        return;
      }

      const findCheck = checked.filter(item => item !== tipo);

      setChecked(findCheck);
    },
    [checked],
  );

  const handleCadastro = useCallback(async () => {
    if (files.length === 0) {
      toast.warning('Por favor! Adicione pelo menos uma foto.');
      return;
    }

    if (!state.name) {
      toast.warning('Por favor! Preencha o campo Nome');
      return;
    }

    if (!state.tipo) {
      toast.warning('Por favor! Preencha o campo Tipo');
      return;
    }

    if (!state.idade) {
      toast.warning('Por favor! Preencha o campo Idade');
      return;
    }

    if (!state.porte) {
      toast.warning('Por favor! Preencha o campo Porte');
      return;
    }

    if (!state.sexo) {
      toast.warning('Por favor! Preencha o campo Sexo');
      return;
    }

    const getFiles = files.map((item, index) => ({ ...item, index }));

    try {
      await api.post('pets', {
        name: state.name,
        birthday: state.date,
        tipo: Number(state.tipo),
        idade: Number(state.idade),
        porte: Number(state.porte),
        sexo: state.sexo,
        heart: checked,
        description: state.description,
        adocao: false,
        active: state.active === 'ativo',
        files: getFiles,
      });

      toast.success('Sucesso! Pet cadastro na lista de adoção.');

      navigate('/app/dashboard');
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
    }
  }, [files, state]);

  return (
    <>
      <SEO title="Adoção" />

      <View>
        <Navigation into="adocao" role={profile.role.title} />

        {!active && (
          <Alert severity="warning">
            Ops! A sua conta ainda consta desativado. Aguarde o administrador
            confirmar.
          </Alert>
        )}
      </View>

      <Grid>
        <aside className="thumbsContainer">
          {loading && (
            <div className="thumb">
              <div className="thumbInner">
                <CircularProgress />
              </div>
            </div>
          )}
          {files.map(file => (
            <div className="thumb" key={file.id}>
              <button
                className="deletar"
                type="button"
                title="deletar"
                onClick={() => handleDelete(file.id)}
              >
                <MdDelete color="#fff" size={16} />
              </button>
              <div className="thumbInner">
                <img src={`${url}${file.file}`} alt="Teste" className="img" />
              </div>
            </div>
          ))}
        </aside>
        <div className="container">
          <div {...getRootProps({ style })}>
            <input
              {...getInputProps()}
              multiple={false}
              disabled={!active}
              onChange={handleFile}
            />
            <p>Arraste e solte alguma imagem aqui ou clique para selecionar</p>
          </div>
        </div>

        <div className="form-input">
          <TextField
            id="nome"
            label="* Nome do Pet"
            type="text"
            disabled={!active}
            className={classes.textField}
            onChange={e => setState({ ...state, name: e.target.value })}
          />
        </div>

        <div className="form-input">
          <TextField
            id="date"
            label="Data de Nascimento"
            type="date"
            className={classes.textField}
            disabled={!active}
            onChange={e => setState({ ...state, date: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">* Tipo</InputLabel>
          <Select
            native
            value={state.tipo}
            disabled={!active}
            onChange={e => {
              setState({ ...state, tipo: e.target.value });
            }}
            inputProps={{
              name: 'tipo',
            }}
          >
            <option aria-label="None" value="" />
            {tabelas.tipo.map(item => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">* Idade</InputLabel>
          <Select
            native
            value={state.idade}
            disabled={!active}
            onChange={e => setState({ ...state, idade: e.target.value })}
            inputProps={{
              name: 'idade',
            }}
          >
            <option aria-label="None" value="" />
            {tabelas.idade.map(item => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">* Porte</InputLabel>
          <Select
            native
            value={state.porte}
            disabled={!active}
            onChange={e => setState({ ...state, porte: e.target.value })}
            inputProps={{
              name: 'porte',
            }}
          >
            <option aria-label="None" value="" />
            {tabelas.porte.map(item => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">* Sexo</InputLabel>
          <Select
            native
            value={state.sexo}
            disabled={!active}
            onChange={e => setState({ ...state, sexo: e.target.value })}
            inputProps={{
              name: 'sexo',
            }}
          >
            <option aria-label="None" value="" />
            <option value="macho">Macho</option>
            <option value="femea">Fêmea</option>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">
            Visualização do pet
          </InputLabel>
          <Select
            native
            value={state.active}
            disabled={!active}
            onChange={e => {
              setState({ ...state, active: e.target.value });
            }}
            inputProps={{
              name: 'active',
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </Select>
        </FormControl>

        <div className="checkbox">
          <Checkbox
            onChange={e =>
              handleChecked({ tipo: 'vacinado', check: e.target.checked })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          Vacinado
          <Checkbox
            onChange={e =>
              handleChecked({ tipo: 'castrado', check: e.target.checked })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          Castrado
          <Checkbox
            onChange={e =>
              handleChecked({ tipo: 'vermifugado', check: e.target.checked })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          Vermifugado
          <Checkbox
            onChange={e =>
              handleChecked({ tipo: 'chipado', check: e.target.checked })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          Microchipado
        </div>

        <textarea
          name="description"
          disabled={!active}
          placeholder="* Escreva uma descrição do seu pet..."
          onChange={e => setState({ ...state, description: e.target.value })}
        />

        <Button variant="contained" onClick={handleCadastro} color="primary">
          Cadastrar Animal
        </Button>
      </Grid>

      <ModalSlide open={open} handleSave={handleSaveFile}>
        <Crop>
          <div className="crop-container">
            <Cropper
              image={file.baseURL}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </Crop>
      </ModalSlide>
    </>
  );
}
