import React from 'react';

import { ButtonNext } from './styles';

const Button = ({ title, description, next, etapaconfirm, handleEtapa, confirm }) => (
  <ButtonNext
    type="button"
    etapa={!!etapaconfirm.find(item => item === next)}
    confirm={confirm}
    onClick={handleEtapa}
  >
    <span><strong>{title}</strong>:</span>
    <p>{description}</p>
  </ButtonNext>
);

export default Button;
