import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import { navigate } from 'gatsby';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';

import { MdDone, MdClose } from 'react-icons/md';

import api from '../../../../services/api';

import { Grid } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Ongs() {
  const classes = useStyles();
  const [pets, setPets] = useState([]);
  const [, setLoading] = useState(true);
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    async function loadPets() {
      const response = await api.get(`getpets`);

      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
      }));

      setLoading(false);
      setPets(percorre);
    }

    loadPets();
  }, []);

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell align="left">Nome do Pet</TableCell>
              <TableCell align="left">Vacinação</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pets.map(row => (
              <TableRow key={row.pet_id}>
                <TableCell component="th" scope="row">
                  <img
                    src={`${url}${row.pet.file.file}`}
                    alt={row.pet.name}
                    style={{ width: 60 }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.pet.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Chip
                    onClick={() => {
                      navigate(`/app/vaccines/${row.pet.slug}`);
                    }}
                    size="small"
                    label={row.pet.vaccine_total}
                    style={{
                      marginRight: 6,
                      backgroundColor: '#0271d1',
                      color: 'white',
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <div className="btn">
                    {row.pet.adocao ? (
                      <>
                        <button
                          type="button"
                          className="done"
                          title="Pet Aceito pela ong"
                        >
                          <MdDone size={18} color="#fff" />
                        </button>
                        <button
                          type="button"
                          className="documento"
                          title="Visualizar Documento"
                          onClick={() =>
                            navigate(`/app/get/pdf/adocao/${row.pet.slug}`)
                          }
                        >
                          Visualizar Documento
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="delete"
                        title="Aguardando a Ong aceitar"
                      >
                        <MdClose size={18} color="#fff" />
                      </button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
