import React, { useState, useCallback, useMemo, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdDelete } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import { BASE } from '../../../../utils/environament';

import { BForm } from './styles';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const url = `${BASE.API}/storage/`;

export default function UploadImage({ file, onChangedImage }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*' });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const handleFile = useCallback(
    async e => {
      const fileTarget = e.target.files[0];

      const urlImagem = URL.createObjectURL(fileTarget);

      const filesPercorre = { files: fileTarget, baseURL: urlImagem };

      const dados = new FormData(); // eslint-disable-line

      dados.append('file', filesPercorre.files);
      try {
        const { data } = await api.post(`files?events=true`, dados, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });

        const filesRes = [data, ...files];

        onChangedImage(data);

        setFiles(filesRes);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    },
    [files],
  );

  const handleDelete = useCallback(
    async e => {
      const getFiles = files.filter(item => item.id !== e);

      setFiles(getFiles);

      await api.delete(`files/${e}`);

      onChangedImage(getFiles);
    },
    [files],
  );

  useEffect(() => {
    setFiles(file);
  }, [file]);

  return (
    <>
      <BForm>
        <h4>Adicionar Imagem</h4>
      </BForm>
      <aside className="thumbsContainer">
        {loading && (
          <div className="thumb">
            <div className="thumbInner">
              <CircularProgress />
            </div>
          </div>
        )}
        {files.map(file => (
          <div className="thumb" key={file.id}>
            <button
              className="deletar"
              type="button"
              title="deletar"
              onClick={() => handleDelete(file.id)}
            >
              <MdDelete color="#fff" size={16} />
            </button>
            <div className="thumbInner">
              <img src={`${url}${file.file}`} alt="Teste" className="img" />
            </div>
          </div>
        ))}
      </aside>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} multiple={false} onChange={handleFile} />
          <p>Arraste e solte alguma imagem aqui ou clique para selecionar</p>
        </div>
      </div>
    </>
  );
}
