import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  .img-avatar {
    width: 100%;
  }
`;

export const HeartClick = styled.div`
  display: ${Props => Props.show ? 'flex' : 'none'};
  flex: 1;
  height: 100%;
  position: absolute;
  z-index: 9;
  background: rgba(255, 255, 255, 0.6);
`;
