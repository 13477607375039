import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, InputLabel } from '@material-ui/core';
import { format, addDays, parseISO } from 'date-fns';

import SEO from '../../../../seo';
import Navigation from '../../../../Navigation/Profile';
import api from '../../../../../services/api';

import * as S from './styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '98%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function EditOrCreateVaccine({ petSlug, vaccineId }) {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [vaccines, setVaccines] = useState([]);
  const [pet, setPet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    vaccine_id: undefined,
    dose: undefined,
    observation: null,
    prevision: null,
    previsionNext: null,
    vaccination: format(new Date(), 'yyyy-MM-dd'),
    pet_id: undefined,
    days: undefined,
    vaccine_pet_id: undefined,
  });

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleDaysDose = event => {
    const convertNumberDose = Number(event.target.value);

    if (convertNumberDose > 0) {
      setForm(state => ({
        ...state,
        days: convertNumberDose,
      }));

      const addDaysDate = addDays(
        new Date(parseISO(form.vaccination)),
        convertNumberDose,
      );

      setForm(state => ({
        ...state,
        previsionNext: format(addDaysDate, 'yyyy-MM-dd'),
      }));
    }
  };

  const requestVaccineIfExists = vaccineIdR => {
    api
      .get(
        `vaccine-pets/verify-if-exists-vaccine?pet_id=${pet?.id}&vaccine_id=${vaccineIdR}`,
      )
      .then(result => {
        const { data } = result.data;

        if (data) {
          setForm(state => ({
            ...state,
            dose: data.dose,
            vaccine_id: vaccineIdR,
            prevision: data.prevision,
            observation: data.observation,
            vaccine_pet_id: data.id,
          }));
        } else {
          setForm(state => ({
            ...state,
            dose: 1,
            vaccine_pet_id: undefined,
          }));
        }
      });
  };

  const handleChangeVaccine = e => {
    const vaccine_id = Number(e.target.value);
    setForm(state => ({ ...state, vaccine_id }));

    requestVaccineIfExists(vaccine_id);
  };

  const handleSubmit = async () => {
    try {
      const schema = Yup.object().shape({
        vaccine_id: Yup.string().required('Por favor! preencha o campo vacina'),
        vaccination: Yup.string().required(
          'Por favor! preencha o campo data da vacinação',
        ),
        days: Yup.string().required(
          'Por favor! preencha o dias da próxima vacinação',
        ),
      });

      const formData = form;

      if (formData.dose == 1) {
        formData.prevision = formData.vaccination;
      }

      await schema.validate(formData);

      if (form.vaccine_pet_id) {
        await api.patch(`vaccine-pets/${form.vaccine_pet_id}`, form);
      } else {
        await api.post('vaccine-pets', form);
      }

      toast.success('Vacina atualizada com sucesso.');
      navigate(`/app/vaccines/${petSlug}`);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        return;
      }
      toast.error('Ops! Algo deu errado.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (vaccineId && pet?.id) {
      requestVaccineIfExists(vaccineId);
    }
  }, [vaccineId, pet?.id]);

  useEffect(() => {
    async function load() {
      const result = await Promise.all([
        api.get('vaccines'),
        api.get(`pets/${petSlug}`),
      ]);

      setVaccines(result[0].data);
      setPet(result[1].data);
      setForm({
        ...form,
        pet_id: result[1].data.id,
      });
    }

    load();
  }, []);

  return (
    <>
      <SEO title="Vacinação de pet" />

      <S.View>
        <Navigation into="perfil" role={profile.role.title} />

        <S.Grid>
          <S.BForm>
            <h4>Adicionar vacina</h4>
          </S.BForm>
          <S.BForm>
            <div className="form-content">
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple" shrink>
                  * Vacina
                </InputLabel>
                <Select
                  native
                  value={form.vaccine_id}
                  onChange={handleChangeVaccine}
                  inputProps={{
                    name: 'vaccine_id',
                    shrink: true,
                  }}
                >
                  <option aria-label="None" value="" />
                  {vaccines.map(item => (
                    <option
                      key={`${item.text}-${item.id}-vaccine`}
                      value={item.id}
                    >
                      {item.text}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="form-input">
              <TextField
                label="Animal"
                type="text"
                name="dose"
                value={pet?.name}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>

            <div className="form-input">
              <TextField
                label="Dosagem"
                type="number"
                name="dose"
                value={form.dose ?? 1}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>

            <div className="form-input">
              <TextField
                id="date"
                label="* Data de vacinação"
                type="date"
                name="vaccination"
                value={form.vaccination}
                className={classes.textField}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="form-input">
              <TextField
                label="* Dias / Próxima vacina"
                type="number"
                name="days"
                onChange={handleDaysDose}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {form.vaccine_pet_id && (
              <div className="form-input">
                <TextField
                  id="date"
                  label="Data de previsão anterior"
                  type="date"
                  name="prevision"
                  value={form.prevision}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </div>
            )}

            <div className="form-input">
              <TextField
                id="date"
                label="Data da próxima dose"
                type="date"
                name="previsionNext"
                value={form.previsionNext}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>

            <div className="form-input">
              <TextField
                label="Observação"
                type="text"
                name="observation"
                value={form.observation}
                className={classes.textField}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </S.BForm>

          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            style={{ marginTop: 12 }}
          >
            Adicionar vacina
          </Button>
        </S.Grid>
      </S.View>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
