import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import api from '../../../../../../services/api';

import MyDocument from './index';

const UserView = ({ token }) => {
  const [form, setForm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadForms() {
      const response = await api.get(`petsforms/${token}`);

      setForm(response.data);
      setLoading(false);
    }

    loadForms();
  }, [token]);

  return (
    <>
      {loading ?
        'carregando' : (
          <PDFViewer style={{ width: '100%', flex: 1, height: 1200, marginTop: 12 }}>
            <MyDocument
              user={form.user}
              profile={form.profile}
              etapadois={form.etapadois}
              etapatres={form.etapatres}
              etapaquarta={form.etapaquarta}
              etapaquinta={form.etapaquinta}
              etapasexta={form.etapasexta}
              etapasetima={form.etapasetima}
            />
          </PDFViewer>
        )}
    </>
  );
};

export default UserView;
