import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';

import { useQueryParam, StringParam } from 'use-query-params';

import api from '../../../../../../services/api';

import MyDocument from './index';
import Navigation from '../../../../../Navigation/Profile';

import { View } from './stylesweb';

const ViewPdf = ({ token }) => {
  const profile = useSelector(state => state.user.profile);
  const [form, setForm] = useState('');
  const [loading, setLoading] = useState(true);
  const [user] = useQueryParam('usuario', StringParam);

  useEffect(() => {
    async function loadForms() {
      const response = await api.get(`petsforms/${token}?usuario=${user}`);

      setForm(response.data);
      setLoading(false);
    }

    loadForms();
  }, [token, user]);

  return (
    <>
      <View>
        <Navigation into="getpet" role={profile.role.title} />
        {loading ? (
          'carregando'
        ) : (
          <PDFViewer
            style={{ width: '100%', flex: 1, height: 1200, marginTop: 12 }}
          >
            <MyDocument
              user={form.user}
              profile={form.profile}
              etapadois={form.etapadois}
              etapatres={form.etapatres}
              etapaquarta={form.etapaquarta}
              etapaquinta={form.etapaquinta}
              etapasexta={form.etapasexta}
              etapasetima={form.etapasetima}
            />
          </PDFViewer>
        )}
      </View>
    </>
  );
};

export default ViewPdf;
