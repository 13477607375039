import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  button {
    width: 100%;
    margin-top: 12px;
  }
`;

export const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-top: ${Props => Props.margin ? '20px' : '0'};
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  .termo {
    display: flex;
    flex-direction: column;

    p + p {
      margin-top: 12px;
    }
  }

  img {
    width: 100%;
  }

  P {
    font-size: 12px;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 6px;
    color: #999;
  }

  h3 {
    width: 100%;
    font-size: 36px;
    margin-bottom: 6px;
    text-align: center;
    color: #008080;
  }

  @media only screen and (min-width: 481px) {
    h4 {
      font-size: 20px;
      margin-bottom: 6px;
    }
  }

  @media only screen and (min-width: 1024px) {
    h4 {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }

  @media only screen and (min-width: 1280px) {
    h4 {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }

  input {
    width: 100%;
    font-size: 12px;
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #FF5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #FF4F4F;
      }
    }

    div {
      width: 100%;
    }
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin: 0px auto;
  border: 6px solid #fff;
  border-radius: 80px;
  background: #fff;
  overflow: hidden;

  img {
    height: 100%;
  }
`;
