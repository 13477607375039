import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-top: ${Props => Props.margin ? '20px' : '0'};
`;

export const ConteudoAvatar = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 12px;
`;

export const Avatar = styled.div`
  display: flex;
  background: #fff;
  padding: 7px;
  border-radius: 50%;
  position: relative;

  img {
    width: 100%;
  }

  label {
    display: block;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 70px;
    background: #fff;
    overflow: hidden;

    .carrega {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #eee;
    }

    input[type='file'] {
      display: none;
    }

    &:hover {
      color: #fff;

      div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  h4 {
    font-size: 24px;
    margin-bottom: 6px;
    color: #999;
  }

  input {
    width: 100%;
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #FF5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #FF4F4F;
      }
    }

    div {
      width: 100%;
    }
  }
`;
