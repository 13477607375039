import React, { useState, useEffect } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaSete = ({ forms, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa7' });
    handleNext({ values, etapa: 'etapafinal' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa7' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>7 - Cuidados veterinários que animalzinho receberá</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Quais vacinas irá dar ao animal adotado?"
                  variant="outlined"
                  name="quaisvacinas"
                  onChange={handleChange}
                  value={values.quaisvacinas}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Qual a marca de ração pretende dar ao adotado? Quais são os seus critérios ao escolher essa alimentação?"
                  variant="outlined"
                  name="marcaracao"
                  onChange={handleChange}
                  value={values.marcaracao}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Pretende por o animalzinho para ter filhotes ou irá castrar? O que pensa a respeito?"
                  variant="outlined"
                  name="castrarfilhote"
                  onChange={handleChange}
                  value={values.castrarfilhote}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="O seu adotado pode viver de 10 a 15 anos. Já pensou sobre essa responsabilidade e está preparado?"
                  variant="outlined"
                  name="viver"
                  onChange={handleChange}
                  value={values.viver}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <p>
                  A adaptação do animal pode levar de 1 semana a 1 mês, tanto
                  com o ambiente como com os outros moradores da casa (contando
                  outros animais também). Está disposto a esperar esse tempo com
                  paciência?
                </p>
                <TextField
                  label="Preencha aqui"
                  variant="outlined"
                  name="terpaciencia"
                  onChange={handleChange}
                  value={values.terpaciencia}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <p>
                  Qual clínica e veterinário levará ou leva os seus animais? Por
                  favor coloque nome do estabelecimento, endereço e nome do
                  veterinário responsável.
                </p>
                <TextField
                  label="Preencha aqui.."
                  variant="outlined"
                  name="qualclinica"
                  onChange={handleChange}
                  value={values.qualclinica}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  O que faria se o animalzinho fosse diagnosticado com as
                  devidas doenças: Calazar/Cinomose/Erliquiose:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Sacrificaria, evitaria gastos com o tratamento e sofrimento do animal"
                    checked={
                      values.diagnosticado ===
                      'Sacrificaria, evitaria gastos com o tratamento e sofrimento do animal'
                    }
                  />
                  Sacrificaria, evitaria gastos com o tratamento e sofrimento do
                  animal
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Devolveria para a ong"
                    checked={values.diagnosticado === 'Devolveria para a ong'}
                  />
                  Devolveria para a ong
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Deixaria na Zoonoses"
                    checked={values.diagnosticado === 'Deixaria na Zoonoses'}
                  />
                  Deixaria na Zoonoses
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Deixaria na rua"
                    checked={values.diagnosticado === 'Deixaria na rua'}
                  />
                  Deixaria na rua
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Doaria para alguém que poderia trata-lo"
                    checked={
                      values.diagnosticado ===
                      'Doaria para alguém que poderia trata-lo'
                    }
                  />
                  Doaria para alguém que poderia trata-lo
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Faria o tratamento até o fim, sem dúvidas"
                    checked={
                      values.diagnosticado ===
                      'Faria o tratamento até o fim, sem dúvidas'
                    }
                  />
                  Faria o tratamento até o fim, sem dúvidas
                </label>
                <label>
                  <Field
                    type="radio"
                    name="diagnosticado"
                    value="Não faria o tratamento, porém, cuidaria dele até o fim"
                    checked={
                      values.diagnosticado ===
                      'Não faria o tratamento, porém, cuidaria dele até o fim'
                    }
                  />
                  Não faria o tratamento, porém, cuidaria dele até o fim
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Conhece essas doenças citadas acima? Sabe as formas de precaução e tratamento?"
                  variant="outlined"
                  name="conhecedoenca"
                  onChange={handleChange}
                  value={values.conhecedoenca}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Seus animais, tomam/tomavam/tomará o remédio de
                  verme/carrapato/pulga com qual frequência?
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="Nunca tomaram"
                    checked={values.remedio === 'Nunca tomaram'}
                  />
                  Nunca tomaram
                </label>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="Uma vez por ano"
                    checked={values.remedio === 'Uma vez por ano'}
                  />
                  Uma vez por ano
                </label>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="A cada 6 meses"
                    checked={values.remedio === 'A cada 6 meses'}
                  />
                  A cada 6 meses
                </label>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="A cada 3 meses"
                    checked={values.remedio === 'A cada 3 meses'}
                  />
                  A cada 3 meses
                </label>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="Raramente"
                    checked={values.remedio === 'Raramente'}
                  />
                  Raramente
                </label>
                <label>
                  <Field
                    type="radio"
                    name="remedio"
                    value="Só quando tem necessidade."
                    checked={values.remedio === 'Só quando tem necessidade.'}
                  />
                  Só quando tem necessidade.
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Com qual frequência leva/levava/levará seus animais para o
                  veterinário?
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="Nunca foram"
                    checked={values.levarveterinario === 'Nunca foram'}
                  />
                  Nunca foram
                </label>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="Uma vez por ano"
                    checked={values.levarveterinario === 'Uma vez por ano'}
                  />
                  Uma vez por ano
                </label>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="A cada 6 meses"
                    checked={values.levarveterinario === 'A cada 6 meses'}
                  />
                  A cada 6 meses
                </label>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="A cada 3 meses"
                    checked={values.levarveterinario === 'A cada 3 meses'}
                  />
                  A cada 3 meses
                </label>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="Raramente"
                    checked={values.levarveterinario === 'Raramente'}
                  />
                  Raramente
                </label>
                <label>
                  <Field
                    type="radio"
                    name="levarveterinario"
                    value="Só quando tem necessidade."
                    checked={
                      values.levarveterinario === 'Só quando tem necessidade.'
                    }
                  />
                  Só quando tem necessidade.
                </label>
              </div>
            </BForm>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E FINALIZAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
