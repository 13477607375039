import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import * as Yup from 'yup';

import Skeleton from '@material-ui/lab/Skeleton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { navigate } from 'gatsby';

import { EtapaUm } from './Etapas/etapaum';
import { EtapaDois } from './Etapas/etapadois';
import { EtapaTres } from './Etapas/etapatres';
import { EtapaQuatro } from './Etapas/etapaquatro';
import { EtapaCinco } from './Etapas/etapacinco';
import { EtapaSeis } from './Etapas/etapaseis';
import { EtapaSete } from './Etapas/etapasete';
import { EtapaFim } from './Etapas/etapaFinal';

import Button from './Etapas/button';
import api from '../../../../services/api';

import SEO from '../../../seo';

import { updateProfilePageRequest } from '../../../../store/modules/user/actions';

import { View, Title, Avatar, Description, Etapas } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Form({ token }) {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const [pet, setPet] = useState('');
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [etapa, setEtapa] = useState({
    confirm: ['etapa1'],
    next: 'etapa1',
  });
  const [forms, setForms] = useState({
    etapaUm: {
      email: '',
      name: '',
      cpf_cnpj: '',
      nascimento: '',
      celular: '',
      cep: '',
      end: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      confirm: false,
    },
    etapaDois: {
      moraem: '',
      suaresidencia: '',
      alugada: '',
      condominio: '',
      obscondominio: '',
      portaodeacesso: '',
      outroportao: '',
      opcoesresidencia: {
        quintal: false,
        area: false,
        varanda: false,
        muroummetro: false,
        murodoismetro: false,
        cercada: false,
        janelas: false,
        gramado: false,
        cimento: false,
        ceramica: false,
        coberto: false,
        parcialcoberto: false,
        protecaocoberto: false,
      },
      confirm: false,
    },
    etapaTres: {
      qtdpessoas: 0,
      quemsao: '',
      acordoadocao: '',
      quemoratrabalha: '',
      moradoralergico: '',
      temcrianca: '',
      casoacidente: '',
      responsavelfin: '',
      reacao: '',
      possuiveiculo: '',
      trabalho: '',
      confirm: false,
    },
    etapaQuarta: {
      jateve: false,
      outroanimal: false,
      quantosespecie: '',
      quaisvacinas: '',
      racoescomem: '',
      cadastrados: '',
      tevefilhotes: '',
      antigoanimal: '',
      confirm: false,
    },
    etapaQuinta: {
      passeios: '',
      cuidadomensal: '',
      sozinho: '',
      passear: '',
      devolveria: '',
      destinoanimal: '',
      outros: '',
      confirm: false,
    },
    etapaSexta: {
      comodo: '',
      ondedormir: '',
      obsamarrado: '',
      animalcresca: '',
      atitudeanimal: '',
      casoviagem: '',
      pretendemudar: '',
      farialatir: '',
      confirm: false,
    },
    etapaSetima: {
      quaisvacinas: '',
      marcaracao: '',
      castrarfilhote: '',
      viver: '',
      terpaciencia: '',
      qualclinica: '',
      diagnosticado: '',
      conhecedoenca: '',
      remedio: '',
      levarveterinario: '',
      confirm: false,
    },
    etapaFim: {
      confirm: '',
    },
  });

  useEffect(() => {
    if (!profile) {
      navigate('/login');
      return;
    }
    async function loadForm() {
      const response = await api.get(`/pets/${token}`);

      const formProfile = profile.profile;

      if (profile) {
        setForms({
          ...forms,
          etapaUm: {
            ...forms.etapaUm,
            email: profile.email,
            name: profile.name,
            cpf_cnpj: formProfile ? formProfile.cpf_cnpj : '',
            nascimento: formProfile ? formProfile.nascimento : '',
            celular: formProfile ? formProfile.celular : '',
            cep: formProfile ? formProfile.cep : '',
            end: formProfile ? formProfile.end : '',
            numero: formProfile ? formProfile.numero : '',
            complemento: formProfile ? formProfile.complemento : '',
            bairro: formProfile ? formProfile.bairro : '',
            cidade: formProfile ? formProfile.cidade : '',
            estado: formProfile ? formProfile.estado : '',
          },
        });
      }

      setFile(response.data.files[0]);
      setPet(response.data);
      setLoading(false);
    }

    loadForm();
  }, [profile, token]);

  const handleNext = useCallback(
    async event => {
      try {
        if (event.etapa === 'etapa2') {
          const schema = Yup.object().shape({
            cpf_cnpj: Yup.string().required(
              'Por favor! preencha o campo CPF ou CNPJ.',
            ),
            nascimento: Yup.string().required(
              'Por favor! preencha o campo nascimento.',
            ),
            celular: Yup.string().required(
              'Por favor! preencha o campo celular.',
            ),
            cep: Yup.string().required('Por favor! preencha o campo cep.'),
            end: Yup.string().required('Por favor! preencha o campo endereço.'),
            bairro: Yup.string().required(
              'Por favor! preencha o campo bairro.',
            ),
            name: Yup.string().required('Por favor! preenche o campo nome'),
          });

          await schema.validate(event.values);
          setForms({ ...forms, etapaUm: { ...event.values, confirm: true } });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapa3') {
          const schema = Yup.object().shape({
            moraem: Yup.string().required(
              'Por favor! marque uma das opções (Mora em)',
            ),
            suaresidencia: Yup.string().required(
              'Por favor! marque uma das opções (Sua redisência fica em condomínio?)',
            ),
            condominio: Yup.string().required(
              'Por favor! marque uma das opções (Sua redisência fica em condomínio?)',
            ),
            portaodeacesso: Yup.string().required(
              'Por favor! marque uma das opções (O portão da sua residência que dá acesso a rua, é)',
            ),
          });

          await schema.validate(event.values);

          setForms({
            ...forms,
            etapaDois: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapa4') {
          const schema = Yup.object().shape({
            qtdpessoas: Yup.string().required(
              'Por favor! preencha o campo (Reside com quantas pessoas?)',
            ),
            responsavelfin: Yup.string().required(
              'Por favor! preencha o campo (Quem será o responsável...)',
            ),
            reacao: Yup.string().required(
              'Por favor! preencha o campo (Se o animal mordesse...)',
            ),
            possuiveiculo: Yup.string().required(
              'Por favor! preencha o campo (Possui veículo para...)',
            ),
            trabalho: Yup.string().required(
              'Por favor! preencha o campo (Você trabalha no momento...)',
            ),
          });

          await schema.validate(event.values);

          if (event.values?.qtdpessoas && event.values.qtdpessoas > 0) {
            const schemaQtdPessoas = Yup.object().shape({
              quemsao: Yup.string().required(
                'Por favor! preencha o campo (Quem São?)',
              ),
              acordoadocao: Yup.string().required(
                'Por favor! preencha o campo (Estão de acordo com a adoção?)',
              ),
              quemoratrabalha: Yup.string().required(
                'Por favor! preencha o campo (As pessoas que moram com...)',
              ),
              moradoralergico: Yup.string().required(
                'Por favor! preencha o campo (Algum morador da residência...)',
              ),
              temcrianca: Yup.string().required(
                'Por favor! preencha o campo (Tem crianças em casa?...)',
              ),
              casoacidente: Yup.string().required(
                'Por favor! preencha o campo (Alguém da casa dirige?...)',
              ),
            });

            await schemaQtdPessoas.validate(event.values);
          }

          setForms({
            ...forms,
            etapaTres: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapa5') {
          if (event.values.jateve || event.values.outroanimal) {
            const schemaJteve = Yup.object().shape({
              quantosespecie: Yup.string().required(
                'Por favor! preencha o campo (Mora em)',
              ),
              quaisvacinas: Yup.string().required(
                'Por favor! preencha o campo (São/eram vacinados?)',
              ),
              racoescomem: Yup.string().required(
                'Por favor! preencha o campo (Qual a marca da ração...)',
              ),
              cadastrados: Yup.string().required(
                'Por favor! preencha o campo (Os seus animais são...)',
              ),
              tevefilhotes: Yup.string().required(
                'Por favor! preencha o campo (Seus animais já tiveram filhotes...)',
              ),
            });

            await schemaJteve.validate(event.values);
          }

          setForms({
            ...forms,
            etapaQuarta: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapa6') {
          const schema = Yup.object().shape({
            destinoanimal: Yup.string().required(
              'Por favor! marque uma das opções (Para qual destino seria...)',
            ),
            devolveria: Yup.string().required(
              'Por favor! marque uma das opções (Devolveria o animal caso precise...)',
            ),
            passear: Yup.string().required(
              'Por favor! marque uma das opções (Conseguem passear com animal...)',
            ),
            sozinho: Yup.string().required(
              'Por favor! marque uma das opções (Quanto tempo o animal...)',
            ),
            cuidadomensal: Yup.string().required(
              'Por favor! marque uma das opções (Qual a quantia mensal que...)',
            ),
            passeios: Yup.string().required(
              'Por favor! marque uma das opções (Em relação aos passeios)',
            ),
          });

          await schema.validate(event.values);

          setForms({
            ...forms,
            etapaQuinta: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapa7') {
          const schema = Yup.object().shape({
            farialatir: Yup.string().required(
              'Por favor! marque uma das opções (O que fará se o animalzinho chorar...)',
            ),
            pretendemudar: Yup.string().required(
              'Por favor! marque uma das opções (Pretende se mudar em um espaço...)',
            ),
            casoviagem: Yup.string().required(
              'Por favor! marque uma das opções (Em caso de viagem, quem ficará...)',
            ),
            atitudeanimal: Yup.string().required(
              'Por favor! marque uma das opções (Caso o animal faça bagunça...)',
            ),
            animalcresca: Yup.string().required(
              'Por favor! marque uma das opções (Caso o animal cresça mais...)',
            ),
            ondedormir: Yup.string().required(
              'Por favor! marque uma das opções (Onde o animalzinho irá dormir)',
            ),
            comodo: Yup.string().required(
              'Por favor! marque uma das opções (Em qual cômodo da casa o animal.)',
            ),
          });

          await schema.validate(event.values);

          setForms({
            ...forms,
            etapaSexta: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
        if (event.etapa === 'etapafinal') {
          const schema = Yup.object().shape({
            levarveterinario: Yup.string().required(
              'Por favor! marque uma das opções (Com qual frequência leva/levava/levará...)',
            ),
            remedio: Yup.string().required(
              'Por favor! marque uma das opções (Seus animais, tomam/tomavam/tomará...)',
            ),
            conhecedoenca: Yup.string().required(
              'Por favor! marque uma das opções (Conhece essas doenças citadas acima?...)',
            ),
            diagnosticado: Yup.string().required(
              'Por favor! marque uma das opções (O que faria se o animalzinho fosse...)',
            ),
            qualclinica: Yup.string().required(
              'Por favor! marque uma das opções (Qual clínica e veterinário levará...)',
            ),
            terpaciencia: Yup.string().required(
              'Por favor! marque uma das opções (A adaptação do animal pode levar de 1...)',
            ),
            viver: Yup.string().required(
              'Por favor! marque uma das opções (O seu adotado pode viver de 10 a 15 anos...)',
            ),
            castrarfilhote: Yup.string().required(
              'Por favor! marque uma das opções (Pretende por o animalzinho para ter...)',
            ),
            marcaracao: Yup.string().required(
              'Por favor! marque uma das opções (Qual a marca de ração pretende dar...)',
            ),
            quaisvacinas: Yup.string().required(
              'Por favor! marque uma das opções (Quais vacinas irá dar...)',
            ),
          });

          await schema.validate(event.values);

          setForms({
            ...forms,
            etapaSetima: { ...event.values, confirm: true },
          });
          setEtapa({
            confirm: [...etapa.confirm, event.etapa],
            next: event.etapa,
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.warning(err.message);
          return;
        }
        toast.error('Ops! Algo deu errado.');
      }
    },
    [forms, etapa],
  );

  const handleEtapa = useCallback(
    event => {
      if (etapa.confirm.find(item => item === event)) {
        setEtapa({ ...etapa, next: event });
      }
    },
    [etapa],
  );

  const handleSubmit = useCallback(
    async event => {
      setOpen(true);
      try {
        const schema = Yup.object().shape({
          confirm: Yup.string().required(
            'Por favor! marque o termo de adoção.',
          ),
        });

        await schema.validate(event.values);

        const etapaum = forms.etapaUm;

        if (!profile.profile_id) {
          const resProfile = await api.post('profile', {
            cpf_cnpj: etapaum.cpf_cnpj.replace(/[^\d]+/g, ''),
            nascimento: etapaum.nascimento,
            celular: etapaum.celular,
            cep: etapaum.cep.replace(/[^\d]+/g, ''),
            end: etapaum.end,
            numero: etapaum.numero,
            complemento: etapaum.complemento,
            bairro: etapaum.bairro,
            cidade: etapaum.cidade,
            estado: etapaum.estado,
            name: etapaum.name,
          });

          if (resProfile.data.error) {
            toast.error(resProfile.data.error);
            return;
          }

          dispatch(updateProfilePageRequest(resProfile.data));
        }

        if (profile.profile_id) {
          const resProfile = await api.put(`profile/${profile.profile_id}`, {
            cpf_cnpj: etapaum.cpf_cnpj.replace(/[^\d]+/g, ''),
            nascimento: etapaum.nascimento,
            celular: etapaum.celular,
            cep: etapaum.cep.replace(/[^\d]+/g, ''),
            end: etapaum.end,
            numero: etapaum.numero,
            complemento: etapaum.complemento,
            bairro: etapaum.bairro,
            cidade: etapaum.cidade,
            estado: etapaum.estado,
            name: etapaum.name,
          });

          dispatch(updateProfilePageRequest(resProfile.data));
        }

        await api.post('getpet', {
          pet_id: pet.id,
        });

        await api.post('petsforms', {
          etapadois: forms.etapaDois,
          etapatres: forms.etapaTres,
          etapaquarta: forms.etapaQuarta,
          etapaquinta: forms.etapaQuinta,
          etapasexta: forms.etapaSexta,
          etapasetima: forms.etapaSetima,
          pet_id: pet.id,
        });

        toast.success('Sucesso! Você solicitou adoção do pet.');
        setOpen(false);
        navigate(`/app/adotar/success/${token}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.warning(err.message);
          setOpen(false);
          return;
        }
        toast.error(err.message);
        setOpen(false);
      }
    },
    [dispatch, forms, profile, token, pet.id],
  );

  return (
    <>
      <SEO title="Pet" />

      <View>
        <Avatar>
          {loading ? (
            <Skeleton variant="circle" width={160} height={160} />
          ) : (
            <img src={`${BASE.API}/storage/${file.file}`} alt={pet.name} />
          )}
        </Avatar>

        <Description>
          Você não pode comprar o amor, mas pode me adotar
        </Description>

        <Title>
          Nome do pet: <h4>{pet.name}</h4>
        </Title>

        <Etapas>
          <Button
            next="etapa1"
            title="Etapa 1"
            description="Dados Pessoais"
            handleEtapa={() => handleEtapa('etapa1')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaUm.confirm}
          />

          <Button
            next="etapa2"
            title="Etapa 2"
            description="Informações sobre a residência"
            handleEtapa={() => handleEtapa('etapa2')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaDois.confirm}
          />

          <Button
            next="etapa3"
            title="Etapa 3"
            description="Informações sobre os moradores da casa"
            handleEtapa={() => handleEtapa('etapa3')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaTres.confirm}
          />

          <Button
            next="etapa4"
            title="Etapa 4"
            description="Informações quanto aos outros animais da casa/animais que criou no passado"
            handleEtapa={() => handleEtapa('etapa4')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaQuarta.confirm}
          />

          <Button
            next="etapa5"
            title="Etapa 5"
            description="Cuidados com o futuro animalzinho"
            handleEtapa={() => handleEtapa('etapa5')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaQuinta.confirm}
          />

          <Button
            next="etapa6"
            title="Etapa 6"
            description="Informações sobre cômodo da casa com o animal"
            handleEtapa={() => handleEtapa('etapa6')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaSexta.confirm}
          />

          <Button
            next="etapa7"
            title="Etapa 7"
            description="Cuidados veterinários que animalzinho receberá"
            handleEtapa={() => handleEtapa('etapa7')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaSetima.confirm}
          />

          <Button
            next="etapafinal"
            title="Etapa Final"
            description="Confirme o termo de adoção"
            handleEtapa={() => handleEtapa('etapafinal')}
            etapaconfirm={etapa.confirm}
            confirm={forms.etapaFim.confirm}
          />
        </Etapas>

        {etapa.next === 'etapa1' && (
          <EtapaUm forms={forms.etapaUm} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa2' && (
          <EtapaDois forms={forms.etapaDois} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa3' && (
          <EtapaTres forms={forms.etapaTres} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa4' && (
          <EtapaQuatro forms={forms.etapaQuarta} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa5' && (
          <EtapaCinco forms={forms.etapaQuinta} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa6' && (
          <EtapaSeis forms={forms.etapaSexta} handleNext={handleNext} />
        )}

        {etapa.next === 'etapa7' && (
          <EtapaSete forms={forms.etapaSetima} handleNext={handleNext} />
        )}

        {etapa.next === 'etapafinal' && (
          <EtapaFim handleSubmit={handleSubmit} forms={forms.etapaFim} />
        )}
      </View>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
