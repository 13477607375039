import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  #container-grid {
    display: block;
    width: 100%;
    padding-top: 12px;
  }

  @media only screen and (min-width: 481px) {
    #container-grid {
      display: grid;
      gap: 6px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 1024px) {
    #container-grid {
      display: grid;
      gap: 6px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (min-width: 1280px) {
    #container-grid {
      display: grid;
      gap: 6px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .img-avatar {
    width: 100%;
  }
`;

export const HeartClick = styled.div`
  display: ${Props => Props.show ? 'flex' : 'none'};
  flex: 1;
  height: 100%;
  position: absolute;
  z-index: 9;
  background: rgba(255, 255, 255, 0.6);
`;
