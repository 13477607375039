import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ShareIcon from '@material-ui/icons/Share';

import { navigate } from 'gatsby';

import { MdStar } from 'react-icons/md';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';
import { updateFavoriteRequest } from '../../../../store/modules/user/actions';

import api from '../../../../services/api';

import SEO from '../../../seo';
import Navigation from '../../../Navigation/Profile';
import LoadingCard from '../../../Loading/Card';

import avatar from '../../../../images/semavatar.jpg';

import { View } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Profile() {
  const profile = useSelector(state => state.user.profile);
  const favorito = useSelector(state => state.user.favorite);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pets, setPets] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    async function loadPets() {
      const response = await api.get(`pets?favourite=true`);

      const percorre = response.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
      }));

      setLoading(false);
      setPets(percorre);
    }

    loadPets();
  }, []);

  async function handleLike(e) {
    if (!e.like) {
      const resLike = await api.post('likes', {
        pet_id: e.id,
      });

      const percorreLike = pets.map(item => ({
        ...item,
        like: item.id === e.id ? true : item.like,
        like_id: item.id === e.id ? resLike.data.id : item.like_id,
      }));

      setPets(percorreLike);
      return;
    }

    await api.delete(`likes/${e.like_id}`);

    const percorreLike = pets.map(item => ({
      ...item,
      like: item.id === e.id ? false : item.like,
      like_id: item.id === e.id ? null : item.like_id,
    }));

    setPets(percorreLike);
  }

  const handleFavorite = async e => {
    if (!e.favourite) {
      const response = await api.post('favourite', {
        pet_id: e.id,
      });

      const percorreFaourite = pets.map(item => ({
        ...item,
        favourite: item.id === e.id ? true : item.favourite,
        favourite_id: item.id === e.id ? response.data.id : item.favourite_id,
      }));

      setPets(percorreFaourite);
      dispatch(updateFavoriteRequest(favorito + 1));
      return;
    }

    await api.delete(`favourite/${e.favourite_id}`);

    const percorreFavourite = pets.filter(item => item.id !== e.id);

    setPets(percorreFavourite);
    dispatch(updateFavoriteRequest(favorito - 1));
  };

  return (
    <>
      <SEO title="Perfil" />
      <View>
        <Navigation into="favorito" role={profile.role.title} />
        <div id="container-grid">
          {loading ? (
            <LoadingCard string="123" />
          ) : (
            <>
              {pets.map(item => (
                <Card key={item.id} className={classes.root}>
                  <CardActionArea
                    onClick={() => navigate(`/app/pet/${item.slug}`)}
                  >
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          {item.user.file ? (
                            <img
                              src={`${url}${item.user.file.file}`}
                              alt={item.user.name}
                              style={{ width: '100%' }}
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt={item.user.name}
                              style={{ width: '100%' }}
                            />
                          )}
                        </Avatar>
                      }
                      title={item.user.name}
                      subheader={`Postado: ${item.dateFormatted}`}
                    />
                    <CardMedia
                      className={classes.media}
                      image={`${url}/${item.file.file}`}
                      title={item.name}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {item.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions disableSpacing>
                    <IconButton
                      aria-label="like"
                      onClick={() =>
                        handleLike({
                          id: item.id,
                          like: item.like,
                          like_id: item.like_id,
                        })
                      }
                    >
                      <FavoriteIcon
                        color={item.like ? 'secondary' : 'disabled'}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() =>
                        handleFavorite({
                          id: item.id,
                          favourite: item.favourite,
                          favourite_id: item.favourite_id,
                        })
                      }
                    >
                      <MdStar color={item.favourite ? '#FF925E' : '#999'} />
                    </IconButton>
                    <IconButton aria-label="add to comment">
                      <ChatBubbleOutlineIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
            </>
          )}
        </div>
      </View>
    </>
  );
}
