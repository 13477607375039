import React, { useEffect, useState } from 'react';

import { Formik, Field, Form } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaDois = ({ forms, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa2' });
    handleNext({ values, etapa: 'etapa3' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa2' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>2 - INFORMAÇÕES SOBRE A RESIDÊNCIA</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">Mora em:</FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="moraem"
                    value="Casa"
                    checked={values.moraem === 'Casa'}
                  />
                  Casa
                </label>
                <label>
                  <Field
                    type="radio"
                    name="moraem"
                    value="Apartamento"
                    checked={values.moraem === 'Apartamento'}
                  />
                  Apartamento
                </label>
                <label>
                  <Field
                    type="radio"
                    name="moraem"
                    value="Sítio"
                    checked={values.moraem === 'Sítio'}
                  />
                  Sítio
                </label>
                <label>
                  <Field
                    type="radio"
                    name="moraem"
                    value="Chácara"
                    checked={values.moraem === 'Chácara'}
                  />
                  Chácara
                </label>
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">Sua residencia é:</FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="suaresidencia"
                    value="Alugada"
                    checked={values.suaresidencia === 'Alugada'}
                  />
                  Alugada
                </label>
                <label>
                  <Field
                    type="radio"
                    name="suaresidencia"
                    value="Própria"
                    checked={values.suaresidencia === 'Própria'}
                  />
                  Própria
                </label>
                <label>
                  <Field
                    type="radio"
                    name="suaresidencia"
                    value="De um parente"
                    checked={values.suaresidencia === 'De um parente'}
                  />
                  De um parente
                </label>
              </div>
            </BForm>
            <BForm disabled={!(values.suaresidencia === 'Alugada')}>
              <div className="form-content">
                <TextField
                  label="Os proprietários aceitam animais?"
                  variant="outlined"
                  name="alugada"
                  value={values.alugada}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Sua redisência fica em condomínio?
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="condominio"
                    value="sim"
                    checked={values.condominio === 'sim'}
                  />
                  Sim
                </label>
                <label>
                  <Field
                    type="radio"
                    name="condominio"
                    value="nao"
                    checked={values.condominio === 'nao'}
                  />
                  Não
                </label>
              </div>
            </BForm>
            <BForm disabled={!(values.condominio === 'sim')}>
              <div className="form-content">
                <TextField
                  label="Quais são as normas a respeito da criação de animais?"
                  variant="outlined"
                  name="obscondominio"
                  value={values.obscondominio}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  O portão da sua residência que dá acesso a rua, é:
                </FormLabel>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Gradeado"
                    checked={values.portaodeacesso === 'Gradeado'}
                  />
                  Gradeado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Parte gradeado, parte fechado"
                    checked={
                      values.portaodeacesso === 'Parte gradeado, parte fechado'
                    }
                  />
                  Parte gradeado, parte fechado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Totalmente fechado"
                    checked={values.portaodeacesso === 'Totalmente fechado'}
                  />
                  Totalmente fechado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Telado"
                    checked={values.portaodeacesso === 'Telado'}
                  />
                  Telado
                </label>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Não tem portão"
                    checked={values.portaodeacesso === 'Não tem portão'}
                  />
                  Não tem portão
                </label>
                <label>
                  <Field
                    type="radio"
                    name="portaodeacesso"
                    value="Outro"
                    checked={values.portaodeacesso === 'Outro'}
                  />
                  Outro
                </label>
              </div>
            </BForm>
            <BForm disabled={values.portaodeacesso !== 'Outro'}>
              <div className="form-content">
                <TextField
                  label="Digite aqui."
                  variant="outlined"
                  name="outroportao"
                  value={values.outroportao}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <FormLabel component="legend">
                  Assinale as opções que sua residência possui:
                </FormLabel>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.quintal"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.quintal}
                  />
                  Quintal
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.area"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.area}
                  />
                  Área
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.varanda"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.varanda}
                  />
                  Varanda
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.muroummetro"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.muroummetro}
                  />
                  Muro com mais de 1 metro
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.murodoismetro"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.murodoismetro}
                  />
                  Muro com mais de 2 metros
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.cercada"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.cercada}
                  />
                  Cercada
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.janelas"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.janelas}
                  />
                  Tem as janelas teladas
                </label>
              </div>
            </BForm>
            <BForm
              disabled={
                !(
                  values?.opcoesresidencia?.quintal ||
                  values?.opcoesresidencia?.area ||
                  values?.opcoesresidencia?.varanda
                )
              }
            >
              <div className="form-content">
                <FormLabel component="legend">
                  Assinale as opções que sua residência possui:
                </FormLabel>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.gramado"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.gramado}
                  />
                  Gramado(a)
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.cimento"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.cimento}
                  />
                  No cimento
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.ceramica"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.ceramica}
                  />
                  Cerâmica
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.coberto"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.coberto}
                  />
                  Coberto(a)
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.parcialcoberto"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.parcialcoberto}
                  />
                  Parcialmente coberto(a)
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="opcoesresidencia.protecaocoberto"
                    onChange={handleChange}
                    checked={!!values?.opcoesresidencia?.protecaocoberto}
                  />
                  Tem proteção contra a chuv
                </label>
              </div>
            </BForm>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
