import styled from 'styled-components';

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  .img-avatar {
    width: 100%;
  }
`;

export const Grid = styled.div`
  display: block;
  width: 100%;
  margin-top: 40px;

  h4 {
    font-size: 28px;
    margin-bottom: 12px;
    background: #FF7C3E;
    border-radius: 8px;
    color: #fff;
    padding: 4px 6px;
  }

  .bloco {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    padding-top: 20px;

    h4 {
      font-size: 38px;
      margin-bottom: 4px;
    }

    .table + .table {
      margin-left: 6px;
      border-left: 1px solid #999;
    }

    .table {
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      p {
        margin-top: -10px;
      }

      div {
        display:block;
        margin: 0;
      }
    }

    .green {
      color: #00CA00;
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding: 4px 6px;
      color: #fff;
      border-radius: 4px;
      background: #FF4D4D;
      margin-left: 6px;
      transition: background-color 0.2s;

      &:hover {
        background: #F90000;
      }
    }

    button + button {
      margin-left: 4px;
    }
  }

  .done {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #00CE00;

    &:hover {
      opacity: 0.5;
    }
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #FF5555;

    &:hover {
      opacity: 0.5;
    }
  }

  .greenbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #00C400;

    &:hover {
      opacity: 0.5;
    }
  }

  .orangebtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #FF8951;

    &:hover {
      opacity: 0.5;
    }
  }

  .redbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #FF4A4A;

    &:hover {
      opacity: 0.5;
    }
  }

  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    color: #fff;
    border-radius: 4px;
    background: #004080;
    transition: background-color 0.2s;

    &:hover {
      background: #0061C1;
    }
  }

  .aceito {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    color: #fff;
    border-radius: 4px;
    background: #00B259;
    transition: background-color 0.2s;

    &:hover {
      background: #00793D;
    }
  }
`;
