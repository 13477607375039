import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

import { navigate } from 'gatsby';
import api from '../../../../../services/api';

import SEO from '../../../../seo';

import { View, Grid, BForm, Avatar } from './styles';

import check from '../../../../../images/check.svg';
import { BASE } from '../../../../../utils/environament';

const Success = ({ token }) => {
  const profile = useSelector(state => state.user.profile);
  const [pet, setPet] = useState('');
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!profile) {
      navigate('/app/login');
      return;
    }
    async function loadForm() {
      const response = await api.get(`/getpet/${token}`);

      if (response.data.error) {
        navigate('/');
        return;
      }

      setPet(response.data);
      setFile(response.data.file);
      setLoading(false);
    }

    loadForm();
  }, []);

  return (
    <>
      <SEO title="Pet" />

      <View>
        <Avatar>
          {loading ? (
            <Skeleton variant="circle" width={160} height={160} />
          ) : (
            <img src={check} alt={pet.name} />
          )}
        </Avatar>

        <Button
          variant="contained"
          color="success"
          onClick={() => navigate('/app/profile')}
        >
          IR PARA MINHA CONTA
        </Button>

        <Grid margin>
          <BForm>
            <h3>Obrigado! Pet solicitado: {pet.name}</h3>
          </BForm>
          <BForm>
            <img src={`${BASE.API}/storage/${file.file}`} alt="pet adoção" />
          </BForm>
        </Grid>
      </View>
    </>
  );
};

export default Success;
