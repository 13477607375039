import styled from 'styled-components';

export const Grid = styled.div`
  display: block;
  width: 100%;

  .grid {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
  }

  button + button {
    margin-left: 0px;
    margin-top: 4px;
  }

  @media only screen and (min-width: 481px) {
    .grid {
      flex-direction: column;
    }

    button + button {
      margin-left: 0px;
      margin-top: 4px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .grid {
      flex-direction: row;
    }

    button + button {
      margin-left: 4px;
      margin-top: 0px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .grid {
      flex-direction: row;
    }

    button + button {
      margin-left: 4px;
      margin-top: 0px;
    }
  }
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  background: ${Props => (Props.ativo ? '#FF8040' : '#0079F2')};
  color: #fff;
  text-align: center;
  border: none;

  &:hover {
    background: #ff8040;
  }

  @media only screen and (min-width: 481px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media only screen and (min-width: 1280px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;
