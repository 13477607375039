import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { navigate } from 'gatsby';

import SEO from '../../seo';
import Navigation from '../../Navigation/Profile';

import UploadBanner from './uploadBanner';
import UploadPartners from './uploadPartners';

import api from '../../../services/api';

import { View, Grid, BForm } from './styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Tools() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState('');
  const [form, setForm] = useState('');

  if (profile.role.title !== 'ADMINISTRADOR') {
    navigate('/app/dashboard');
  }

  useEffect(() => {
    async function loadtools() {
      const response = await api.get('posts/findtype?type=history');

      setHistory(response.data.data);
    }

    loadtools();
  }, []);

  const handleChange = useCallback(event => {
    const text = event.target.value;
    const textbr = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

    setForm(textbr);
  }, []);

  const handleUpdateHistory = useCallback(async () => {
    try {
      setLoading(true);

      if (!history) {
        await api.post(`posts`, {
          body: form,
          type: 'history',
        });
        return;
      }

      await api.patch(`posts/${history.id}`, {
        body: form,
      });

      setLoading(false);
      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }, [form]);

  return (
    <>
      <SEO title="Configurações" />

      <View>
        <Navigation into="history" role={profile.role.title} />
      </View>

      <UploadPartners />

      <UploadBanner />

      <Grid>
        <BForm>
          <h4>Atualizar História</h4>
        </BForm>
        <BForm>
          <div className="form-content">
            <textarea
              placeholder="Escreva aqui..."
              defaultValue={history?.body ?? ''}
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <button
              type="button"
              className="btn-save"
              onClick={handleUpdateHistory}
            >
              ATUALIZAR HISTÓIRIA
            </button>
          </div>
        </BForm>
      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
