import React, { useEffect, useState } from 'react';

import { Formik, Form } from 'formik';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import {
  saveFormLocalStorage,
  getFormLocalStorage,
} from '../../../../../utils/localStorage';

import { Grid, BForm } from './styles';

export const EtapaTres = ({ forms, idUser, handleNext }) => {
  const [data, setData] = useState({});
  const [showForm, setShowForm] = useState(false);

  const handleEtapaNext = values => {
    saveFormLocalStorage({ values, etapa: 'etapa3', user: idUser });
    handleNext({ values, etapa: 'etapa4' });
  };

  useEffect(() => {
    const getForm = getFormLocalStorage({ etapa: 'etapa3' });

    if (getForm && !showForm) {
      setData(getForm);
      setShowForm(true);
      return;
    }

    setData(forms);
  }, [forms]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={values => handleEtapaNext(values)}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <Grid>
            <BForm>
              <h4>3 - INFORMAÇÕES SOBRE OS MORADORES DA CASA</h4>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  type="number"
                  label="Reside com quantas pessoas?"
                  variant="outlined"
                  name="qtdpessoas"
                  value={values.qtdpessoas}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            {values.qtdpessoas > 0 && (
              <>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Quem São?"
                      variant="outlined"
                      name="quemsao"
                      value={values.quemsao}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <FormControl>
                      <InputLabel htmlFor="acordo">
                        Estão de acordo com a adoção?
                      </InputLabel>
                      <Select
                        native
                        name="acordoadocao"
                        onChange={handleChange}
                        variant="outlined"
                      >
                        <option aria-label="None" value="" />
                        <option value="sim">Sim</option>
                        <option value="nao">Não</option>
                      </Select>
                    </FormControl>
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="As pessoas que moram com você trabalham? Qual a profissão delas?"
                      variant="outlined"
                      name="quemoratrabalha"
                      value={values.quemoratrabalha}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Algum morador da residência é alérgico?"
                      variant="outlined"
                      name="moradoralergico"
                      value={values.moradoralergico}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Tem crianças em casa? Caso tenha, qual a idade?"
                      variant="outlined"
                      name="temcrianca"
                      value={values.temcrianca}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
                <BForm>
                  <div className="form-content">
                    <TextField
                      label="Alguém da casa dirige? Teria como levar o animalzinho imediatamente ao veterinário em caso de acidentes?"
                      variant="outlined"
                      name="casoacidente"
                      value={values.casoacidente}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </BForm>
              </>
            )}
            <BForm>
              <div className="form-content">
                <TextField
                  label="Quem será o responsável financeiro pelo animal?"
                  variant="outlined"
                  name="responsavelfin"
                  value={values.responsavelfin}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Se o animal mordesse/arranhasse você/seu filho/outro membro da família como seria a reação?"
                  variant="outlined"
                  name="reacao"
                  value={values.reacao}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Possui veículo para o transporte do animal? Caso sim, qual? Caso não, como levará o animal para o veterinário?"
                  variant="outlined"
                  name="possuiveiculo"
                  value={values.possuiveiculo}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
            <BForm>
              <div className="form-content">
                <TextField
                  label="Você trabalha no momento? Qual sua profissão/emprego."
                  variant="outlined"
                  name="trabalho"
                  value={values.trabalho}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </BForm>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SALVAR E CONTINUAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
