import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import * as Yup from 'yup';

import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import SEO from '../../../seo';

import api from '../../../../services/api';

import CnpjMaskCustom from '../../../MaskInput/cnpj';
import CpfMaskCustom from '../../../MaskInput/cpf';
import PhoneMaskCustom from '../../../MaskInput/celular';
import CepMaskCustom from '../../../MaskInput/cep';

import cep from '../../../../services/cep';

import Navigation from '../../../Navigation/Profile';
import { signOut } from '../../../../store/modules/auth/actions';

import {
  updateAvatarRequest,
  updateProfilePageRequest,
} from '../../../../store/modules/user/actions';

import { View, Grid, ConteudoAvatar, Avatar, BForm } from './styles';

import avatar from '../../../../images/semavatar.jpg';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Edit() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [loadAvatar, setLoadAvatar] = useState(false);
  const [forms, setForms] = useState({
    email: '',
    name: '',
    cpf_cnpj: '',
    nascimento: '',
    celular: '',
    cep: '',
    end: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    password: '',
    passwordRepeat: '',
  });
  const url = `${BASE.API}/storage/`;

  useEffect(() => {
    async function loadUser() {
      const { data } = await api.get(`users/${profile.id}`);

      setForms({
        ...forms,
        email: data.email,
        name: data.name,
        cpf_cnpj: data.profile ? data.profile.cpf_cnpj : '',
        nascimento: data.profile ? data.profile.nascimento : '',
        celular: data.profile ? data.profile.celular : '',
        cep: data.profile ? data.profile.cep : '',
        end: data.profile ? data.profile.end : '',
        numero: data.profile ? data.profile.numero : '',
        complemento: data.profile ? data.profile.complemento : '',
        bairro: data.profile ? data.profile.bairro : '',
        cidade: data.profile ? data.profile.cidade : '',
        estado: data.profile ? data.profile.estado : '',
      });

      dispatch(updateProfilePageRequest(data));
    }

    loadUser();
  }, [profile.id]);

  function handleSignOut() {
    dispatch(signOut());
  }

  async function handleCep(event) {
    const count = event.replace(/[^\d]+/g, '');

    setForms({
      ...forms,
      cep: event,
    });

    if (count.length <= 7) {
      return;
    }

    const { data } = await cep.get(`${count}/json/`);

    if (data.erro) {
      toast.error('Ops! O cep informado está errado.');
      return;
    }

    setForms({
      ...forms,
      cep: data.cep,
      end: data.logradouro,
      bairro: data.bairro,
      cidade: data.localidade,
      estado: data.uf,
    });
  }

  const handleChange = useCallback(
    event => {
      setForms({
        ...forms,
        [event.target.name]: event.target.value,
      });
    },
    [forms],
  );

  const handleAvatar = useCallback(
    async e => {
      setLoadAvatar(true);
      const data = new FormData(); // eslint-disable-line

      data.append('file', e.target.files[0]);

      const response = await api.post('files', data);

      const { id } = response.data;

      dispatch(updateAvatarRequest({ file_id: id }));
      setLoadAvatar(false);
    },
    [dispatch],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('Por favor! preencha o campo Email'),
        name: Yup.string().required('Por favor! preencha o campo Nome'),
        cpf_cnpj: Yup.string().required(
          'Por favor! preencha o campo CPF ou CNPJ.',
        ),
        nascimento: Yup.string().required(
          'Por favor! preencha o campo nascimento.',
        ),
        celular: Yup.string().required('Por favor! preencha o campo celular.'),
        cep: Yup.string().required('Por favor! preencha o campo cep.'),
        end: Yup.string().required('Por favor! preencha o campo endereço.'),
        bairro: Yup.string().required('Por favor! preencha o campo bairro.'),
        cidade: Yup.string().required('Por favor! preencha o campo cidade.'),
      });

      await schema.validate(forms);

      if (forms.password) {
        const schemaPassword = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
          passwordRepeat: Yup.string().oneOf(
            [Yup.ref('password'), ''],
            'A senha não corresponde',
          ),
        });

        await schemaPassword.validate(forms);
      }

      if (!profile.profile_id) {
        const resProfile = await api.post('profile', {
          cpf_cnpj: forms.cpf_cnpj.replace(/[^\d]+/g, ''),
          nascimento: forms.nascimento,
          celular: forms.celular,
          cep: forms.cep.replace(/[^\d]+/g, ''),
          end: forms.end,
          numero: forms.numero,
          complemento: forms.complemento,
          bairro: forms.bairro,
          cidade: forms.cidade,
          estado: forms.estado,
          name: forms.name,
          email: forms.email,
          ...(forms.password
            ? {
                password: forms.password,
                passwordRepeat: forms.passwordRepeat,
              }
            : {}),
        });

        if (resProfile.data.error) {
          toast.error(resProfile.data.error);
          return;
        }

        dispatch(updateProfilePageRequest(resProfile.data));
      }

      if (profile.profile_id) {
        const resProfile = await api.put(`profile/${profile.profile_id}`, {
          cpf_cnpj: forms.cpf_cnpj.replace(/[^\d]+/g, ''),
          nascimento: forms.nascimento,
          celular: forms.celular,
          cep: forms.cep.replace(/[^\d]+/g, ''),
          end: forms.end,
          numero: forms.numero,
          complemento: forms.complemento,
          bairro: forms.bairro,
          cidade: forms.cidade,
          estado: forms.estado,
          name: forms.name,
          email: forms.email,
          ...(forms.password
            ? {
                password: forms.password,
                passwordRepeat: forms.passwordRepeat,
              }
            : {}),
        });

        dispatch(updateProfilePageRequest(resProfile.data));
      }

      toast.success('Profile atualizado com sucesso!');
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        setLoading(false);
        return;
      }
      toast.error('Ops! Algo deu errado.');
      setLoading(false);
    }
  }, [forms, dispatch]);

  return (
    <>
      <SEO title="Editar Perfil" />

      <View>
        <Navigation into="edit" role={profile.role.title} />
      </View>

      <ConteudoAvatar>
        <Avatar>
          <label>
            {loadAvatar ? (
              <div className="carrega">
                <CircularProgress />
              </div>
            ) : (
              <>
                {profile.file ? (
                  <img src={`${url}${profile.file.file}`} alt={profile.name} />
                ) : (
                  <img src={avatar} alt={profile.name} />
                )}

                <input
                  type="file"
                  id="avatar"
                  accept="image/*"
                  onChange={handleAvatar}
                />

                <div>
                  <p>Edit</p>
                </div>
              </>
            )}
          </label>
        </Avatar>
      </ConteudoAvatar>

      <Grid>
        <BForm>
          <h4>Profile (dados)</h4>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              value={forms.email}
              onChange={handleChange}
              disabled
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Nome Completo"
              variant="outlined"
              name="name"
              value={forms.name}
              onChange={handleChange}
            />
          </div>
        </BForm>
        {profile.role.title !== 'MODERADOR' ? (
          <BForm>
            <div className="form-content">
              <TextField
                label="CPF"
                name="cpf_cnpj"
                variant="outlined"
                value={forms.cpf_cnpj}
                InputProps={{
                  inputComponent: CpfMaskCustom,
                }}
                InputLabelProps={{
                  shrink: !!forms.cpf_cnpj,
                }}
                onChange={handleChange}
              />
            </div>
          </BForm>
        ) : (
          <BForm>
            <div className="form-content">
              <TextField
                label="CNPJ"
                name="cpf_cnpj"
                variant="outlined"
                value={forms.cpf_cnpj}
                InputProps={{
                  inputComponent: CnpjMaskCustom,
                }}
                InputLabelProps={{
                  shrink: !!forms.cpf_cnpj,
                }}
                onChange={handleChange}
              />
            </div>
          </BForm>
        )}
        <BForm>
          <div className="form-content">
            <TextField
              type="date"
              label="Data de Nascimento"
              variant="outlined"
              name="nascimento"
              value={forms.nascimento}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </div>
          <div className="form-content">
            <TextField
              label="Celular"
              variant="outlined"
              name="celular"
              value={forms.celular}
              InputProps={{
                inputComponent: PhoneMaskCustom,
              }}
              InputLabelProps={{
                shrink: !!forms.celular,
              }}
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Cep"
              name="cep"
              variant="outlined"
              value={forms.cep}
              onChange={e => handleCep(e.target.value)}
              InputProps={{
                inputComponent: CepMaskCustom,
              }}
              InputLabelProps={{
                shrink: !!forms.cep,
              }}
            />
          </div>
          <div className="form-content">
            <TextField
              label="Endereço"
              variant="outlined"
              name="end"
              value={forms.end}
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Número"
              variant="outlined"
              name="numero"
              value={forms.numero}
              onChange={handleChange}
            />
          </div>
          <div className="form-content">
            <TextField
              label="Complemento"
              variant="outlined"
              name="complemento"
              value={forms.complemento}
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Bairro"
              variant="outlined"
              name="bairro"
              value={forms.bairro}
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Cidade"
              variant="outlined"
              name="cidade"
              disabled
              value={forms.cidade}
              onChange={handleChange}
            />
          </div>
          <div className="form-content">
            <TextField
              label="Estado"
              disabled
              variant="outlined"
              name="estado"
              value={forms.estado}
              onChange={handleChange}
            />
          </div>
        </BForm>
      </Grid>

      <Grid margin>
        <BForm>
          <h4>Alterar Senha</h4>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Senha"
              type="password"
              variant="outlined"
              name="paswword"
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <TextField
              label="Repete a Senha"
              type="password"
              variant="outlined"
              name="paswwordRepeat"
              onChange={handleChange}
            />
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <button type="button" className="btn-save" onClick={handleSubmit}>
              SALVAR
            </button>
          </div>
        </BForm>
        <BForm>
          <div className="form-content">
            <button
              type="button"
              className="btn-delete"
              onClick={handleSignOut}
            >
              SAIR
            </button>
          </div>
        </BForm>
      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
