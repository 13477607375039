export const saveFormLocalStorage = ({ values, etapa }) => {
  localStorage.setItem(`@adotaform-${etapa}`, JSON.stringify(values)); // eslint-disable-line
};

export const getFormLocalStorage = ({ etapa }) => {
  const getForm = localStorage.getItem(`@adotaform-${etapa}`); // eslint-disable-line

  if (getForm) {
    const parseForm = JSON.parse(getForm);
    return parseForm;
  }

  return null;
};
