import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Modal = ({ open, handleClose, handleSave, title, children }) => {

  return (
    <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {handleClose && (
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          )}
          <Button onClick={handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
  </Dialog>
  );
};

export default Modal;
