import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  padding-top: 12px;

  @media only screen and (min-width: 481px) {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1280px) {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Grid = styled.div`
  display: block;
  width: 100%;
  margin-top: 40px;

  .bloco {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    padding-top: 20px;

    h4 {
      font-size: 38px;
      margin-bottom: 4px;
    }

    .table + .table {
      margin-left: 6px;
      border-left: 1px solid #999;
    }

    .table {
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      p {
        margin-top: -10px;
      }

      div {
        display:block;
        margin: 0;
      }
    }

    .green {
      color: #00CA00;
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }

  .done {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #00CE00;

    &:hover {
      opacity: 0.5;
    }
  }

  .documento {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    height: 26px;
    background: #FF8000;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;
    color: #fff;

    &:hover {
      opacity: 0.5;
    }
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #FF5555;

    &:hover {
      opacity: 0.5;
    }
  }

  .greenbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #00C400;

    &:hover {
      opacity: 0.5;
    }
  }

  .bluebtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #1E90FF;

    &:hover {
      opacity: 0.5;
    }
  }

  .orangebtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #FF8951;

    &:hover {
      opacity: 0.5;
    }
  }

  .redbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    background: #FF4A4A;

    &:hover {
      opacity: 0.5;
    }
  }

  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: #004080;

    &:hover {
      opacity: 0.5;
    }
  }
`;
