import React from 'react';
import { navigate } from 'gatsby'; // eslint-disable-line

import { Grid, Button } from './styles';

const NgProfile = ({ into, role }) => (
  <Grid>
    <div className="grid">
      {role === 'MODERADOR' ? (
        <Button
          type="button"
          ativo={into === 'perfil'}
          onClick={() => navigate('/app/dashboard')}
        >
          Meus Pets
        </Button>
      ) : (
        <Button
          type="button"
          ativo={into === 'perfil'}
          onClick={() => navigate('/app/dashboard')}
        >
          {role === 'ADMINISTRADOR' ? 'Dashboard' : 'Minhas Adoções'}
        </Button>
      )}
      {role === 'MODERADOR' && (
        <Button
          type="button"
          ativo={into === 'adocao'}
          onClick={() => navigate('/app/adocao')}
        >
          Adicionar Adoção
        </Button>
      )}
      <Button
        type="button"
        ativo={into === 'favorito'}
        onClick={() => navigate('/app/favorito')}
      >
        Meus Favoritos
      </Button>
      <Button
        type="button"
        ativo={into === 'edit'}
        onClick={() => navigate('/app/editar')}
      >
        Editar Informações
      </Button>
      {role === 'ADMINISTRADOR' && (
        <Button
          type="button"
          ativo={into === 'events'}
          onClick={() => navigate('/app/eventos')}
        >
          Eventos
        </Button>
      )}
      {role === 'ADMINISTRADOR' && (
        <Button
          type="button"
          ativo={into === 'history'}
          onClick={() => navigate('/app/tools')}
        >
          Configurações
        </Button>
      )}
    </div>
  </Grid>
);

export default NgProfile;
