import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-top: ${Props => (Props.margin ? '20px' : '0')};
`;

export const BForm2 = styled.div`
  ${props =>
    props.disabled
      ? css`
          display: none;
        `
      : css`
          display: flex;
          flex-direction: column;
        `}
`;

export const BForm = styled.div`
  flex: 1;
  flex-direction: row;

  label {
    display: flex;
    align-items: center;
    margin-top: 12px;

    input {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }

  ${props =>
    props.disabled
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  .termo {
    display: flex;
    flex-direction: column;

    p + p {
      margin-top: 12px;
    }

    .paragrafo {
      padding-left: 30px;
    }
  }

  img {
    width: 100%;
  }

  P {
    font-size: 12px;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 6px;
    color: #999;
  }

  h3 {
    width: 100%;
    font-size: 36px;
    margin-bottom: 6px;
    text-align: center;
    color: #008080;
  }

  @media only screen and (min-width: 481px) {
    h4 {
      font-size: 20px;
      margin-bottom: 6px;
    }
  }

  @media only screen and (min-width: 1024px) {
    h4 {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }

  @media only screen and (min-width: 1280px) {
    h4 {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }

  input {
    width: 100%;
    font-size: 12px;
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    p {
      margin-top: 0px;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #ff5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #ff4f4f;
      }
    }

    div {
      width: 100%;
    }
  }
`;

export const ButtonNext = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  background: #999;
  border-radius: 4px;
  padding: 6px 12px;
  border: 0;
  color: #fff;
  transition: background-color 0.2s;
  cursor: default;

  ${props =>
    props.etapa &&
    css`
      background: #ff4d4d;
      cursor: pointer;

      &:hover {
        background: ${shade(0.3, '#FF4D4D')};
      }
    `}

  ${props =>
    props.confirm &&
    css`
      background: #008c69;

      &:hover {
        background: ${shade(0.3, '#008C69')};
      }
    `}

  p {
    flex: 1;
    font-size: 14px;
    margin: 0;
  }

  span {
    margin-right: 4px;
    font-size: 14px;
  }
`;
