import styled from 'styled-components';

export const TableView = styled.div`
  .delete {
    width: 28px;
    height: 28px;
    border: 0;
    background: #ff5353;
    border-radius: 14px;
  }

  .done {
    width: 28px;
    height: 28px;
    border: 0;
    background: #debd18;
    margin-right: 8px;
    border-radius: 14px;
  }
`;

export const View = styled.section`
  display: block;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 8px;

  #container-grid {
    display: flex;
    width: 100%;
    padding-top: 12px;
    justify-content: space-between;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 18px 16px;
  border-radius: 10px;

  .thumb {
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    padding: 8px;

    input {
      width: 100%;
      height: 28px;
      padding: 4px;
      margin-top: 4px;
    }

    .deletar {
      width: 28px;
      height: 28px;
      position: absolute;
      border: 0;
      background: #ff5353;
      border-radius: 14px;
      top: -15px;
      right: -15px;
    }
  }

  .upload {
    display: block;
    padding-bottom: 8px;
  }

  .form-input + .form-input {
    margin-top: 12px;
  }

  textarea {
    width: 100%;
    height: 180px;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #ccc;

    &:hover {
      border: 1px solid #0059b2;
    }
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #ff5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #ff4f4f;
      }
    }

    div {
      width: 100%;
    }
  }
`;

export const Button = styled.button`
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background: #23df1a;
  color: #000;
  text-align: center;
  border: none;

  &:hover {
    background: #ff8040;
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  textarea {
    width: 100%;
    height: 120px;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #ccc;

    &:hover {
      border: 1px solid #0059b2;
    }
  }

  h4 {
    font-size: 24px;
    margin-bottom: 6px;
    color: #999;
  }

  input {
    width: 100%;
  }

  .form-content + .form-content {
    margin-left: 4px;
  }

  .form-content {
    display: block;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;

    button {
      width: 100%;
    }

    .btn-save {
      border: 0;
      border-radius: 4px;
      background: #22ab6a;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #198951;
      }
    }

    .btn-delete {
      border: 0;
      border-radius: 4px;
      background: #ff5959;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      font-size: 16px;

      &:hover {
        background: #ff4f4f;
      }
    }

    div {
      width: 100%;
    }
  }
`;
