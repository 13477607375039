import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { Container } from './styles';

export default function Alert(props) {
  return (
    <Container>
      <MuiAlert elevation={6} variant="filled" {...props} />
    </Container>
  );
}
